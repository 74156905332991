/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import Modal from 'react-modal'
import Cookies from 'universal-cookie'
import Map from '@components/store-locator/map'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import { validateZip, weekdays } from '@helpers/string-helper'
import { getLatLng, getZipFromSearch } from '@helpers/geo-location'
import { useApolloClient } from '@apollo/client'
import { number, string, bool, func } from 'prop-types'
import { getSeeInStore } from '@services/product'
import '../../../assets/css/components/product/product-parts/see-in-stores-modal.sass'
import StoreInfo from '../../store-locator/store-info'
import StoreSearchBar from '../../store-locator/search-bar'
import MapLocationsList from '../../store-locator/map-locations-list'

const cookies = new Cookies()

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const StoresListContainer = styled('div')(({ theme, isMobile }) => ({
  margin: '0 auto',
  textAlign: 'center',
  width: isMobile ? 'auto' : '45%',
  height: '100%',
  overflow: 'auto',
  '& > div': {
    flexDirection: 'column',
    width: 'auto',
    marginTop: '1rem',
    '.MuiFormControl-root': {
      'label:not(.Mui-focused)': {
        color: theme.palette.grey[500],
      },
      div: {
        fieldset: {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
    },
    '.MuiPaper-root': {
      div: {
        textAlign: 'left',
      },
    },
  },
}))

const Title = styled('h1')({
  color: '#333',
  fontWeight: 'bold',
  textAlign: 'center',
  textTransform: 'uppercase',
  padding: 0,
  fontSize: '1.25em',
  margin: '10px 0',
})

/**
 * Necessary to sync up Strapi hours schema with Stores microservice (https://stores.rtg-dev.com) hours schema
 * Example:
 * Strapi object
 * {
        "day": "Sunday",
        "openTime": "11:00",
        "closeTime": "18:00"
    },
    vs
  * Store microservice
    {
        "dayIndex": "0",
        "openTime": "11:00",
        "closeTime": "18:00"
    },
 * @param {*} hours
   @returns {object}
 */
const mapHours = hours =>
  hours.map(h => ({
    day: weekdays[h.dayIndex],
    ...h,
  }))

const SeeInStoresModal = ({ storeLat, storeLng, lia, sku, zip, modalOpen, closeModal, title, primary_image }) => {
  const [currentCoordinates, setCurrentCoordinates] = useState({ currentLat: 0, currentLng: 0 })
  const [invalidAddress, setInvalidAddress] = useState(false)
  const [addressInput, setAddressInput] = useState('')
  const [currentDay, setCurrentDay] = useState('')
  const [tomorrowDay, setTomorrowDay] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedMarker, setSelectedMarker] = useState({})
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [available, setAvailable] = useState(false)
  const [submittedZip, setSubmittedZip] = useState('')
  const [doneLoading, setDoneLoading] = useState(false)
  const [markers, setMarkers] = useState([])
  const markerObjects = useRef([])
  const apolloClient = useApolloClient()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const getInitialCenter = useCallback(() => {
    let lat
    let lng
    if (storeLat && storeLng) {
      lat = storeLat
      lng = storeLng
    } else if (markers.length) {
      lat = markers[0].lat
      lng = markers[0].lng
    } else if (cookies.get('__Host-rtg_location') !== undefined) {
      const rtg_location = cookies.get('__Host-rtg_location')
      lat = rtg_location.lat
      lng = rtg_location.long
    } else {
      lat = 28.004051
      lng = -82.308449
    }

    if (lat) {
      setCurrentCoordinates({ currentLat: lat, currentLng: lng })
    }
  }, [markers, storeLat, storeLng])

  const updateInputAddressValue = evt => {
    setAddressInput(evt.target.value)
    setSubmittedZip('')
  }

  const changeAddress = async () => {
    setInvalidAddress(false)
    if (addressInput === '') {
      getInitialCenter()
      return
    }

    const location = await getLatLng(addressInput, apolloClient)
    if (location.lat) {
      setCurrentCoordinates({ currentLat: location.lat, currentLng: location.lng })
    } else {
      setInvalidAddress(true)
    }
    setSubmittedZip(addressInput)
    setAvailable(true)
    setMapMarkers()
  }

  const onMarkerMounted = element => {
    if (element?.marker) {
      markerObjects?.current.push?.(() => element.marker)
    }
  }

  const onLocationClick = (index, marker) => {
    const i = parseInt(index)
    if (i > -1 && typeof markerObjects?.current[index] === 'function') {
      setActiveMarker(markerObjects?.current?.[index]?.())
      setSelectedMarker(marker)
      setShowingInfoWindow(true)
    }
  }

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false)
      setActiveMarker(null)
      setSelectedMarker({})
    }
  }

  const setMapMarkers = useCallback(async () => {
    let zipData = addressInput || zip || cookies.get('__Host-rtg_zip') || cookies.get('__Host-rtg_location')?.zip
    if (sku && typeof sku !== 'undefined' && zipData !== '' && typeof zipData !== 'undefined') {
      const fetchedMarkers = []
      if (!validateZip(zipData)) {
        zipData = await getZipFromSearch(apolloClient, addressInput)
      }
      getSeeInStore(sku, zipData)
        .then(data => {
          if (data && data.length) {
            data.forEach(addr => {
              const hours = mapHours(addr.hours?.regularHours)
              const config = {
                title: addr.address1,
                name: addr.storeName,
                lat: addr.latitude,
                lng: addr.longitude,
                city: addr.city,
                state: addr.state,
                storeNumber: addr.storeNumber,
                zip: addr.zipcode,
                phoneNumber: addr.phoneNumber,
                hours,
                ratings: addr?.ratings,
              }
              fetchedMarkers.push(config)
            })
            setMarkers(fetchedMarkers)
            setAvailable(true)
          } else {
            setMarkers([])
          }
          setDoneLoading(true)
        })
        .catch(() => {
          setAvailable(false)
          setDoneLoading(true)
        })
    }
  }, [addressInput, apolloClient, sku, zip])

  const getLocations = () => {
    if (!markers || !markers.length || !available)
      return (
        <div>
          {(doneLoading && submittedZip && !available) ||
            (doneLoading && available && markers.length === 0 && (
              <p className="message">
                We apologize, but this product is not available at any locations near {submittedZip}.
              </p>
            ))}
          {doneLoading && !submittedZip && !available && (
            <p className="message">We apologize, but this product is not on display in your region</p>
          )}
          {!doneLoading && <p className="message">Loading ...</p>}
        </div>
      )
    return (
      <MapLocationsList
        markers={markers}
        onLocationClick={onLocationClick}
        currentDay={currentDay}
        tomorrowDay={tomorrowDay}
        lia={lia}
        stores={markers}
        selectedMarker={selectedMarker}
        showRatings
      />
    )
  }

  const renderMap = () => (
    <Map
      currentCoordinates={currentCoordinates}
      markers={markers}
      onLocationClick={onLocationClick}
      onMarkerMounted={onMarkerMounted}
      onMapClicked={onMapClicked}
      activeMarker={activeMarker}
      showingInfoWindow={showingInfoWindow}
      selectedMarker={selectedMarker}
      zoom={8}
      ignoreDistance
      hideCurrentMarker
    >
      <StoreInfo selectedMarker={selectedMarker} />
    </Map>
  )

  useEffect(() => {
    getInitialCenter()
  }, [getInitialCenter])

  useEffect(() => {
    if (submittedZip !== '' || addressInput === '') setMapMarkers()
  }, [addressInput, setMapMarkers, submittedZip])

  markerObjects.current = []

  if (!sku || typeof sku === 'undefined') return null
  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="See In Stores Modal"
      className="modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content see-in-store-modal">
        <div className="card grid-x">
          <StoresListContainer isMobile={isMobile}>
            <Title>{title || ''}</Title>
            <img src={`${primary_image}&w=250`} alt={title} />
            <StoreSearchBar
              invalidAddress={invalidAddress}
              addressInput={addressInput}
              updateInputAddressValue={updateInputAddressValue}
              changeAddress={changeAddress}
            />
            <h2 className="heading">Available To See At The Following Showrooms</h2>
            {modalOpen && getLocations()}
          </StoresListContainer>
          {markers && markers.length > 0 && (
            <div className="map">
              <div className="mapHolder" id="mapHolder">
                {modalOpen && renderMap()}
              </div>
            </div>
          )}
        </div>
        <button
          className="close-modal"
          tabIndex="0"
          value="Close"
          aria-label="Close"
          onClick={closeModal}
          type="button"
        >
          <img
            className="icon close"
            alt="close icon"
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
          />
        </button>
      </div>
    </Modal>
  )
}

SeeInStoresModal.propTypes = {
  closeModal: func,
  lia: bool,
  modalOpen: bool,
  primary_image: string,
  sku: string,
  storeLat: number,
  storeLng: number,
  title: string,
  zip: string,
}

export default SeeInStoresModal
