// dependencies
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { string, bool } from 'prop-types'

// helpers
import { addToDataLayer, addToDataLayer_nextgen } from '@helpers/google-tag-manager'

// components
import Button from '@mui/material/Button'
import SeeInStoresModal from './see-in-stores-modal'

function SeeInStores({ componentPage, customButtonText, lia, primary_image, sku, title, zip }) {
  const shippingAddress = useSelector(s => s?.location?.shipping_address)
  const [modalOpen, setModalOpen] = useState(false)

  const onSeeInStore = () => {
    setModalOpen(true)
    addToDataLayer_nextgen('see_in_store', {
      delivery_to: shippingAddress,
    })
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        data-testid="seeInStoreButton"
        onClick={() => onSeeInStore()}
        color="primary"
        style={{ width: '100%' }}
      >
        See In Store
      </Button>
      {modalOpen && (
        <SeeInStoresModal
          modalOpen={modalOpen}
          closeModal={closeModal}
          zip={zip}
          lia={lia}
          sku={sku}
          title={title}
          primary_image={primary_image}
        />
      )}
    </>
  )
}

SeeInStores.propTypes = {
  componentPage: string,
  customButtonText: string,
  lia: bool,
  primary_image: string,
  title: string,
  sku: string,
  zip: string,
}

export default SeeInStores
