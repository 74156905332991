/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { object, objectOf, any, arrayOf, number, bool, node, string, func } from 'prop-types'
import classNames from 'classnames'
import { Paper, Typography } from '@mui/material'
import SlickSlider from '@rtgdev/react-slick'
import '@comp-sass/shared/slider/slick.scss'
import '@comp-sass/shared/slider/slick-theme.scss'
import '@comp-sass/shared/slider.sass'
import { getWindowSizeSettings } from '@helpers/slider'
import useWindowResize from '@helpers/useWindowResize'
import styled, { css } from 'styled-components'
import { breakPoints } from '@constants/styles'
import SliderArrow from './slider-arrow'
import RTGLink from './link'

const ExtraMargin = styled.div`
  margin: 3.5rem 0;

  @media only screen and (max-width: ${breakPoints.small}) {
    margin: 2.5rem 0;
  }
`
const SliderWrapper = styled.div`
  ${({ slidesCount, addWrapper }) => {
    if (!addWrapper) return null
    if (slidesCount === 1) {
      return css`
        @media only screen and (min-width: ${breakPoints.medium}) {
          .slick-track {
            justify-content: space-around;
            .slick-slide {
              max-width: 30%;
              min-width: 350px;
            }
        }
      `
    }
    if (slidesCount > 1 && slidesCount < 4) {
      return css`
        @media only screen and (min-width: ${breakPoints.medium}) {
          .slick-track {
            justify-content: space-around;
        }
      `
    }
    return null
  }}
`
const StyledHeadingContainer = styled.div`
  margin: 20px 0;
`
const SimpleSlider = ({
  className,
  slideId,
  data,
  maxSlides,
  minSlidesMobile,
  children,
  dots,
  focusOnSelect,
  lazyLoad,
  cssHideClass,
  testid,
  type,
  label,
  instructions,
  slideOnClick,
  slideOnKeyDown,
  initialSlide: initialSlideProps,
  noExtraMargin,
}) => {
  const sliderEle = useRef(null)
  const initialWindow = typeof window !== 'undefined' ? window.innerWidth : null
  const [windowWidth, setWindowWidth] = useState(initialWindow)
  const slider = data || null

  const checkWindowWidth = () => {
    if (window && window.innerWidth && window.innerWidth !== windowWidth) setWindowWidth(window.innerWidth)
  }

  useWindowResize(checkWindowWidth, initialWindow)

  if (slider && slider.title)
    slider.slides = slider.slides.map(slide => {
      slide.sliderTitle = slider.title
      return slide
    })

  const minSlides = minSlidesMobile
  const contentComponents = children

  const infinite = false
  const initialSlide = initialSlideProps || 0
  const nextArrow = slider && (
    <SliderArrow
      text="Next"
      caption={slider.caption}
      describedby={slider.describedby}
      label={slider.label}
      classNames="slick-next"
      instance={sliderEle}
      heading={slider.heading}
    />
  )
  const prevArrow = slider && (
    <SliderArrow
      text="Previous"
      caption={slider.caption}
      describedby={slider.describedby}
      label={slider.label}
      classNames="slick-prev"
      instance={sliderEle}
      heading={slider.heading}
    />
  )
  const settings =
    slider &&
    getWindowSizeSettings({
      maxSlides,
      minSlides,
      nextArrow,
      prevArrow,
      lazyLoad,
      initialSlide,
      infinite,
      windowWidth,
      focusOnSelect,
      dots,
      sliderEle,
      sliderTitle: slider.title || slider.heading || '',
      cssHideClass,
      testid,
      type,
      label,
      instructions,
      slideOnClick,
      slideOnKeyDown,
    })

  if (contentComponents) {
    if (slider.viewAllLink) {
      if (slider.viewAllLink.displayText && !slider.viewAllLink.displayText.includes('>')) {
        slider.viewAllLink.displayText = `${slider.viewAllLink.displayText} >`
      } else if (slider.viewAllLink.text && !slider.viewAllLink.text.includes('>')) {
        slider.viewAllLink.text = `${slider.viewAllLink.text} >`
      }
    }
  }

  // FD-2500 - Complete Your Room slider should have white background and smaller size
  // This paper wrap should probably be refactored into parent BelowTheFold component
  const addWrapper =
    slider &&
    ['complete your room', 'you may also like', 'similar products', 'also in this collection'].includes(
      slider.heading.toLowerCase(),
    )

  let content = (
    <>
      {contentComponents && (
        <div
          ref={sliderEle}
          id={slideId}
          className={classNames(
            'slider',
            {
              small: slider.sliderSize && slider.sliderSize === 'Small',
            },
            className,
          )}
        >
          <div className="slider-head">
            {slider.heading && (
              <StyledHeadingContainer
                id={slider.ariaId}
                className="slider-heading"
                role="heading"
                aria-level={slider.headingLevel || 2}
                margin="20px 0;"
              >
                <span className="hide508">{slider.ariaNote} </span>
                <Typography color="textPrimary" variant="h5" fontWeight={300}>
                  {slider.heading.toUpperCase()}
                </Typography>
              </StyledHeadingContainer>
            )}
            <div className="slider-link">{slider.viewAllLink && <RTGLink data={slider.viewAllLink} />}</div>
          </div>
          <SliderWrapper slidesCount={contentComponents?.length} addWrapper={addWrapper}>
            <SlickSlider {...settings}>{contentComponents}</SlickSlider>
          </SliderWrapper>
        </div>
      )}
    </>
  )

  if (addWrapper) {
    content = <Paper sx={{ paddingTop: '1rem' }}>{content}</Paper>
  }

  if (noExtraMargin || slider?.sliderSize === 'Small') return content
  return <ExtraMargin>{content}</ExtraMargin>
}

SimpleSlider.propTypes = {
  className: string,
  slideId: string,
  data: objectOf(any),
  disabledCoupons: arrayOf(any),
  maxSlides: number,
  minSlidesMobile: number,
  page: object,
  children: node,
  dots: bool,
  lazyLoad: bool,
  focusOnSelect: bool,
  initialSlide: number,
  cssHideClass: string,
  testid: string,
  type: string,
  label: string,
  instructions: string,
  slideOnClick: func,
  slideOnKeyDown: func,
  noExtraMargin: bool,
}

export default SimpleSlider

export const sliderFragment = graphql`
  fragment Slider on ContentfulSlider {
    title
    heading
    sliderSize
    startDate
    endDate
    viewAllLink {
      ...Link
    }
    slides {
      __typename
      ...ProductTile
      ...CategoryTile
      ...CollectionTile
      ...Coupon
      ...BannerButton
      ...Markdown
      ...Button
    }
    contentful_id
  }
`
