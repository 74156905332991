// dependencies
import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { styled, useMediaQuery, Grid, Box, Hidden, Typography, NoSsr, Button, Stack, useTheme } from '@mui/material'
import { Quantity, SystemProvider } from '@rtgdev/design-system'

// helpers
import { navigate } from '@helpers/link'
import { isEmpty } from 'lodash'

import ApplyButton from '@shared/apply-button'

// helpers
import { renderLTLmessage, enhanceStockMessage, renderSeeInStore, sortMattressSizeVariations } from '@helpers/product'
import ShopAndDeliverTo from '@shared/ShopAndDeliverTo'
import { getCurrentLocation } from '@helpers/geo-location'
import { currencyFormatUS } from '@helpers/string-helper'
import { addToDataLayer } from '@helpers/google-tag-manager'
import { formatReviews } from '@helpers/reviews'
import { getFinancePlans, displayKlarnaPDPWidget } from '@helpers/finance'
import { isInViewport } from '@helpers/viewport'
import { productUrl } from '@helpers/route'
import { useTest } from '@hooks/useTest'
import { useUpdateFavorites, useIsFavorited } from '@hooks/auth'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import { scrollTo } from '@helpers/bodyscroll'
import MattressFoundationModal from '@shared/modals/mattress-foundation-modal'
import { AddonModelPropType } from '@models/api/products/addon-model'
import PageLoader from '@components/shared/page-loader'
import { useMyStore } from '@hooks/useMyStore'
import { ADJUSTABLE_BASE_STRING, HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE } from '@constants/addons'
import { useProduct, FETCH_PROPERTIES } from '../hooks'
import { FAVORITE_BUTTON } from '../../../@rtg2022/index'

// components
import ProductPrice from '../../../@rtg2022/components/atoms/Price/ProductPrice'
import FoundationChooser from '../../../@rtg2022/Select/FoundationChooser'
import StockMessage from '../../../@rtg2022/components/atoms/StockMessage/StockMessage'
import ReviewsShort from '../../../@rtg2022/components/organisms/Reviews/reviews-short'
import LoadProductVariations from '../product-parts/product-variations-refactor'
import Options from '../product-parts/product-options'
import ProductAddToCartSection from '../product-parts/product-add-to-cart-section'
import SeeInStores from '../product-parts/product-see-in-stores-wrapper'

import Addons from './Addons'
import Branding from './Branding'
import 'react-image-lightbox/style.css'

const StyledLoadProductVariations = styled(LoadProductVariations)(({ isMattressWithFoundations, isMobile }) => ({
  ...(isMattressWithFoundations &&
    !isMobile && {
      '& > div': {
        width: 'calc(100% - 125px)',
      },
    }),
}))

const TitleDiv = styled('h1')(({ theme }) => ({
  color: '#333',
  textAlign: 'left',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
    padding: 0,
    lineHeight: '1.625rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    padding: '0 0 5px 0',
    fontWeight: 700,
  },
}))

const SkuDiv = styled('div')(({ theme }) => ({
  fontSize: '0.75rem',
  color: '#333',
  padding: '0 0 0 1px',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const DesktopProductPriceWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}))

const PriceWrapper = styled(Grid)(({ theme, isMobile }) => ({
  ...(!isMobile && {
    margin: '0 0 20px 0 !important',
  }),

  'klarna-placement > div': {
    marginTop: '10px',
  },
  a: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    lineHeight: '24px',
    fontSize: '12px',
    padding: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const StyledAddToCartWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StockWrapperDesktop = styled(Grid)(({ theme }) => ({
  margin: '20px 0 0',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const StockWrapperMobile = styled(Grid)`
  display: block;
  margin: 10px 0 20px 0;
`
const VariationWrapperDesktop = styled(Grid)(({ theme }) => ({
  padding: '0 0 15px 0',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const VariationWrapperMobile = styled(Grid)(({ theme }) => ({
  padding: '15px 0',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

const FreeShippingStyled = styled('div')(({ theme }) => ({
  color: theme?.palette?.tertiary?.main,
  fontWeight: 600,
  fontSize: '1rem',
  textTransform: 'uppercase',
}))

const StockMessageStyled = styled('span')`
  font-size: 1rem;
`
const SpanStyled = styled('span')`
  height: 1.25rem;
  display: grid;
`
const AssemblyRequiredStyled = styled('div')`
  font-size: 1rem;
  font-weight: 700;
`
const LTLmessageStyled = styled('span')`
  font-size: 1rem;
`
const QuantityWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const BoxDesktopMargin = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: '10px',
  },
}))

const QuantityContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  ' > div.MuiGrid-root': {
    width: 'auto',
    flexWrap: 'nowrap',
  },
}))

const HrNoTopMargin = styled('hr')`
  margin-top: 0;
`
const HrNoBottomMargin = styled('hr')`
  margin-bottom: 0;
`
const RoomIncludes = styled(Box)(({ theme }) => ({
  fontWeight: 700,
  textTransform: 'capitalize',
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'start',
  [theme.breakpoints.up('md')]: {
    fontSize: '100%',
  },
}))

const RoomIncludesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: 0,
  paddingBottom: '10px',
  [theme.breakpoints.up('md')]: {
    padding: '15px 0',
  },
  [theme.breakpoints.up('sm')]: {
    padding: '15px 0',
  },
}))

const RoomIncludesBox = styled(Box)`
  margin-right: 10px;
  display: flex;
  align-items: start;
  font-size: 1rem;
`

const StyledFavoriteButton = styled(Button)(({ theme }) => ({
  paddingRight: '20px',
  p: {
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    textTransform: 'uppercase',
    lineHeight: '15px',
    color: theme.palette.primary.main,
  },
}))

const StyledFoundationChooser = styled(FoundationChooser)({})

const StyledModalBannerButton = styled(Button)(() => ({
  maxHeight: '40px',
}))

const StyledFoundationsChooserContainer = styled('div')(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  ...(isMobile && {
    flexDirection: 'column',
    alignItems: 'flex-start',
  }),
  '& > button': {
    fontSize: '0.7rem',
    width: '125px',
    textTransform: 'capitalize',
    textDecoration: 'underline',
    ...(isMobile && {
      padding: 0,
      width: 'auto',
      justifyContent: 'flex-start',
    }),
  },
  '& > div:first-child': {
    flex: 1,
    ...(!isMobile && { width: 'calc(100% - 125px)' }),
    '.MuiSelect-select': {
      maxWidth: 'unset',
    },
  },
}))

const StyledReviewsShort = styled(ReviewsShort)(({ theme }) => ({
  '.MuiRating-root': {
    svg: { fill: `${theme.palette.secondary.lighter} !important` },
    '.MuiRating-iconFilled': {
      svg: { fill: '#FFCB00 !important' },
    },
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: '1rem',
  },
}))

const StyledColorBox = styled(Box)({
  textTransform: 'capitalize',
})

const StyledAddToCartSection = styled(Stack)(({ theme }) => ({
  '> div': {
    flex: '1 1 auto',
  },
  '.FavoriteIconButton': {
    position: 'relative',
    top: '8px',
  },
}))

const ITEM_TYPES = {
  ROOM: 'room',
}

const SCROLL_POSITION = 860
const DEBOUNCE_STOCK_MESSAGE_TIME = 250
const PREQUALIFIED_LINK = `${process.env.GATSBY_SYNCHRONY_URL}&preQual=Y&sitecode=`

const ProductInfo = ({
  product,
  strikeThrough,
  strikeThroughPrice,
  free_shipping,
  userSelectedAddons,
  setUserSelectedAddons,
  region,
  requiredAddonsSelected,
  items_in_room,
  room_savings,
  foundationsMattressBanner,
  setFoundationSelection,
  foundationSelection,
}) => {
  const {
    isMattressWithFoundations,
    isMattress,
    price,
    sale,
    addons,
    availableAddons,
    foundationOptions,
    financeAmount,
    financeNumMonths,
    promotionalPrice,
    promotionalMonths,
    showFinance,
    availability,
    stockMessage,
    inStores,
    routeToSimilarItems,
    region: productRegion,
    city: productCity,
    title,
    image,
    reviews,
  } = useProduct(
    {
      product,
      foundationSelection,
    },
    [FETCH_PROPERTIES.AVAILABILITY_STOCKMESSAGE, FETCH_PROPERTIES.PRODUCT_REVIEWS],
  )

  const { myStore, relatedStores, renderMyStoreModalLocator, setMapUiDrawerIsOpen } = useMyStore()

  const isProductInStore = useMemo(
    () =>
      inStores?.includes(myStore?.storeNumber?.toString()) ||
      !!relatedStores?.filter(relatedStore => inStores?.includes(relatedStore?.storeNumber?.toString()))?.length,
    [inStores, myStore.storeNumber, relatedStores],
  )

  const [shouldHideAddToCartWrapper, setShouldHideAddToCartWrapper] = useState(false)
  const [warrantyEnabled, setWarrantyEnabled] = useState(false)
  const [displayLTLmessage, setDisplayLTLmessage] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [scrolled, setScrolled] = useState(false)
  const [showFoundationModal, setShowFoundationModal] = useState(false)
  const { generateTestId } = useTest()
  const theme = useTheme()
  const initialShippingAddress = `${productCity}, ${productRegion}`
  const location = product?.availabilityInfo?.location || getCurrentLocation()
  const zone = parseInt(location.price_zone)
  const isOnSale = item => item?.on_sale?.[`${region}_${zone}`]

  const isMobile = useMediaQuery(t => t.breakpoints.down('md'))

  const [mattressOnly, setMattressOnly] = useState(false)

  const formattedReviews = useMemo(() => formatReviews({ title, image, reviewsData: reviews }), [image, reviews, title])
  const isFavorited = useIsFavorited(product.sku)
  const handleFavoriteClick = useUpdateFavorites(product)

  const handleReviewsClick = () => {
    const reviewsButton = document.querySelector('.reviews-tab')
    reviewsButton?.click()
    scrollTo('store-reviews-accordion')
    if (typeof window !== 'undefined') {
      window.dataLayer.push({
        category: 'PDP Product Reviews',
        event: 'read reviews hotlink',
        action: 'click',
        productSku: product?.sku,
      })
    }
  }

  const getFoundationPrice = selection => {
    if (!selection?.pricing) {
      return price
    }

    return isOnSale(selection)
      ? selection?.pricing?.[`${region}_${zone}_sale_price`] + price
      : selection?.pricing?.[`${region}_${zone}_list_price`] + price
  }

  const foundationOptionPrice = getFoundationPrice(foundationSelection)

  const renderSeeInStoreButton = renderSeeInStore({
    region,
    delivery_type: product.delivery_type,
  })

  useEffect(() => {
    updateScrollPosition()

    window.addEventListener('scroll', toggleAddProductToCartVisibility, false)
    window.addEventListener('scroll', updateScrollPosition)

    return () => {
      window.removeEventListener('scroll', toggleAddProductToCartVisibility)
      window.removeEventListener('scroll', updateScrollPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (product) {
      setMattressOnly(product.feature === 'mattress in a box')
    }
    renderLTLmessage({
      delivery_sub_type_code: product?.delivery_sub_type_code,
      vendorId: product?.vendorId,
    }).then(setDisplayLTLmessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const updateScrollPosition = () => {
    if (window.scrollY > SCROLL_POSITION && !scrolled) {
      setScrolled(true)
    } else if (window.scrollY <= SCROLL_POSITION && scrolled) {
      setScrolled(false)
    }
  }

  /* If an "add offer to cart" button is in the viewport, hide the "add product to cart" button */
  const toggleAddProductToCartVisibility = () => {
    if (isMobile) {
      const offerButtons = document.querySelectorAll('.offerButton')

      if (offerButtons) {
        const hasOfferInView = Array.from(offerButtons).some(isInViewport)

        if (hasOfferInView && !shouldHideAddToCartWrapper) {
          setShouldHideAddToCartWrapper(true)
        }
        if (!hasOfferInView && shouldHideAddToCartWrapper) {
          setShouldHideAddToCartWrapper(false)
        }
      }
    }
  }

  const handleOptionClick = (label, trackingData) => {
    //  Append tracking data to window for analytics purposes
    addToDataLayer(trackingData)

    //  Get url and route
    const url = productUrl(product.upgrade_items.filter(i => i.label === label)[0].route)
    navigate(url)
  }

  const [optionalAddons, setOptionalAddons] = useState(availableAddons?.filter(addon => !addon.addon_required))

  // Remove Frames/Bases from addons when some foundation is selected (FD-1964)
  useEffect(() => {
    if (!isEmpty(foundationSelection) && foundationSelection?.title?.includes(ADJUSTABLE_BASE_STRING)) {
      setOptionalAddons(prev => prev.filter(addon => !HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE.includes(addon.sku)))
      setUserSelectedAddons(prev => prev.filter(addon => !HIDDEN_ADDONS_FOR_ADJUSTABLE_BASE.includes(addon.sku)))
    } else {
      setOptionalAddons(availableAddons?.filter(addon => !addon.addon_required))
    }
  }, [availableAddons, foundationSelection, setOptionalAddons, setUserSelectedAddons])

  const financeAmountText = `$${financeAmount}`

  /* create the customer assembly required message */
  const assemblyRequired = product.customer_assembly_required
  const assemblyRequiredNote =
    assemblyRequired && (Object.is(product.delivery_type, 'O') || Object.is(product.delivery_type, 'U'))
      ? 'Assembly required'
      : false

  /* if the user is out of market or there are no addons or warranty isn't available in any region  */
  const noAddons =
    Object.is(region, 'OOM') ||
    Object.is(optionalAddons?.length, 0 || Object.is(product.warranty_pricing?.length, 0) || !getStateEligiblity()) ||
    !availability

  const LTLmessageContent = () => (
    <>
      <Typography variant="subtitle">
        The carrier will contact you directly to schedule your delivery, which includes placing the box into the first
        room of your home. <strong>Please note that freight deliveries can take 7-21 days in transit.</strong>
      </Typography>
    </>
  )

  const showKlarna = displayKlarnaPDPWidget({ delivery_type: product.delivery_type, showFinance, price })

  const renderSeeInStores = () => (
    <Stack spacing={2}>
      {renderSeeInStoreButton && (
        <>
          <Button
            variant="outlined"
            data-testid="seeInStoreButton"
            onClick={() => setMapUiDrawerIsOpen(true)}
            color="primary"
            style={{ width: '100%' }}
          >
            See In Store
          </Button>
        </>
      )}
    </Stack>
  )

  const renderFoundationsChooser = () => (
    <>
      <MattressFoundationModal
        data={foundationsMattressBanner}
        shouldShowModal={showFoundationModal}
        closeModal={() => setShowFoundationModal(false)}
      />
      <StyledFoundationsChooserContainer isMobile={isMobile}>
        <StyledFoundationChooser
          foundations={foundationOptions}
          region={region}
          isOnSale={isOnSale}
          zone={zone}
          onSelect={setFoundationSelection}
          foundationSelection={foundationSelection}
        />
        <StyledModalBannerButton variant="text" onClick={() => setShowFoundationModal(true)}>
          How to choose the right foundation?
        </StyledModalBannerButton>
      </StyledFoundationsChooserContainer>
    </>
  )

  if (!product || !stockMessage) return <PageLoader />

  const productTitle = isMattress
    ? product?.title || product?.displayNamePDP
    : product?.displayNamePDP || product?.title

  return (
    <SystemProvider>
      {renderMyStoreModalLocator({ product: { ...product, isProductInStore } })}
      <Grid item xs={12}>
        {!isMobile ? (
          <>
            {product.brand && (
              <Grid item xs={12}>
                <Branding brand={product.brand} />
              </Grid>
            )}
          </>
        ) : (
          <>
            {product.brand && (
              <Grid container item xs={12} justifyContent="center">
                <Branding brand={product.brand} />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          <TitleDiv data-testid={generateTestId('pdp', 'title')}>
            <span dangerouslySetInnerHTML={{ __html: productTitle }} />
            <Hidden mdDown implementation="css">
              <FAVORITE_BUTTON checked={isFavorited} handleClick={handleFavoriteClick} sku={product?.sku} />
            </Hidden>
          </TitleDiv>
          <SkuDiv>{`SKU: ${product.sku}`}</SkuDiv>
          <StyledReviewsShort
            reviewsData={formattedReviews}
            onReviewsClick={handleReviewsClick}
            showAverageText={false}
          />
        </Grid>
        {/* ITEMS IN ROOM - DESKTOP */}
        <Hidden implementation="css" only="xs">
          {items_in_room && (
            <RoomIncludesWrapper>
              <RoomIncludesBox>Includes:</RoomIncludesBox>
              <RoomIncludes>
                {items_in_room
                  .map(({ quantity: item_quantity, generic_name }) =>
                    item_quantity > 1 ? `${generic_name} (${item_quantity})` : generic_name,
                  )
                  .join(' • ')}
              </RoomIncludes>
            </RoomIncludesWrapper>
          )}
        </Hidden>
        {/* PRODUCT VARIANTS */}
        <div />
        {product.variations && (
          <>
            <Hidden implementation="css" smDown>
              <VariationWrapperDesktop item xs={12}>
                {product.variations.color ? (
                  <StyledLoadProductVariations
                    variations={product.variations.color}
                    heading="Color"
                    productSku={product.sku}
                    isMattressWithFoundations={isMattressWithFoundations}
                    isMobile={isMobile}
                    location={location}
                  />
                ) : null}
                {product.variations.finish ? (
                  <StyledLoadProductVariations
                    variations={product.variations.finish}
                    heading="Finish"
                    productSku={product.sku}
                    isMattressWithFoundations={isMattressWithFoundations}
                    isMobile={isMobile}
                    location={location}
                  />
                ) : null}
                {product.variations.size ? (
                  <StyledLoadProductVariations
                    variations={
                      isMattress ? sortMattressSizeVariations(product.variations.size) : product.variations.size
                    }
                    heading="Size"
                    productSku={product.sku}
                    isMattressWithFoundations={isMattressWithFoundations}
                    isMobile={isMobile}
                    location={location}
                  />
                ) : null}
              </VariationWrapperDesktop>
            </Hidden>
            <VariationWrapperMobile item xs={12}>
              {product.variations.color ? (
                <StyledLoadProductVariations
                  variations={product.variations.color}
                  heading="Color"
                  productSku={product.sku}
                  location={location}
                />
              ) : null}
              {product.variations.finish ? (
                <StyledLoadProductVariations
                  variations={product.variations.finish}
                  heading="Finish"
                  productSku={product.sku}
                  location={location}
                />
              ) : null}
              {product.variations.size ? (
                <StyledLoadProductVariations
                  variations={
                    isMattress ? sortMattressSizeVariations(product.variations.size) : product.variations.size
                  }
                  heading="Size"
                  productSku={product.sku}
                  location={location}
                />
              ) : null}
            </VariationWrapperMobile>
          </>
        )}
        {/* New Mattress PDP variations */}
        {isMattressWithFoundations && (
          <>
            <VariationWrapperDesktop
              item
              xs={12}
              sx={{
                marginTop: product.variations ? '0' : '20px',
              }}
            >
              {renderFoundationsChooser()}
            </VariationWrapperDesktop>
            <Hidden implementation="css" mdUp>
              <VariationWrapperMobile item xs={12}>
                {renderFoundationsChooser()}
              </VariationWrapperMobile>
            </Hidden>
          </>
        )}
        {/* PRODUCT COLOR */}
        {product.color && product.displayNamePDP && !product.variations && (
          <>
            <Grid container direction="column" justifyContent="flex-start">
              <Box display="flex">
                <span>{`Color: `}</span>
                <StyledColorBox fontWeight={700} ml="15px">
                  {product.color}
                </StyledColorBox>
              </Box>
            </Grid>
          </>
        )}
        {/* ITEMS IN ROOM - MOBILE */}
        <Hidden implementation="css" mdUp>
          {items_in_room && (
            <RoomIncludesWrapper>
              <RoomIncludesBox>Includes:</RoomIncludesBox>
              <RoomIncludes>
                {items_in_room
                  .map(({ quantity: item_quantity, generic_name }) =>
                    item_quantity > 1 ? `${generic_name} (${item_quantity})` : generic_name,
                  )
                  .join(' • ')}
              </RoomIncludes>
            </RoomIncludesWrapper>
          )}
        </Hidden>
        {/* PRODUCT UPGRADE OPTIONS */}
        {product.upgrade_items && !isMattressWithFoundations && (
          <Grid item xs={12}>
            <Options
              options={product.upgrade_items}
              productSku={product.sku}
              handleChange={handleOptionClick}
              filterAvailability={product.filterAvailability}
            />
          </Grid>
        )}
        {/* PRODUCT (BESIDES ROOMS) QUANTITY PICKER */}
        {product.type && product.type !== ITEM_TYPES.ROOM && (
          <Grid item xs={12}>
            <HrNoBottomMargin />
            <QuantityWrapper>
              <BoxDesktopMargin>Quantity:</BoxDesktopMargin>
              <QuantityContainer>
                <Quantity initialQuantity={quantity} onDecrement={setQuantity} onIncrement={setQuantity} />
              </QuantityContainer>
            </QuantityWrapper>
            <Hidden implementation="css" mdUp>
              <HrNoTopMargin />
            </Hidden>
          </Grid>
        )}
        {/* PRODUCT PRICE -- Desktop */}
        <DesktopProductPriceWrapper>
          <PriceWrapper item>
            <HrNoTopMargin />
            <ProductPrice
              price={currencyFormatUS(!isMattressWithFoundations ? price : foundationOptionPrice)}
              showStrikeThrough={isEmpty(foundationSelection)}
              strikeThroughPrice={currencyFormatUS(strikeThroughPrice)}
              sale={sale || strikeThrough}
              strikeThrough={strikeThrough}
              availability={availability}
              freeShipping={free_shipping}
              freeShippingMattressOnly={mattressOnly}
              financeAmount={financeAmountText}
              monthly={financeNumMonths}
              promotionalPrice={promotionalPrice}
              promotionalMonths={promotionalMonths}
              showFinancing={showFinance}
              room_savings={!!room_savings && !isMobile ? room_savings : 0}
              prequalifiedLink={`${PREQUALIFIED_LINK}rgbcml5d1&modalapply=true`}
              prequalifyButton={() => (
                <ApplyButton
                  asButton
                  url={`${PREQUALIFIED_LINK}rgbcml5d1&modalapply=true`}
                  category="pdp"
                  action="see-prequalify"
                  title="See if you Prequalify"
                  data-testid={generateTestId('prequalify', 'link')}
                >
                  See if you Prequalify
                </ApplyButton>
              )}
            />
            {showKlarna && (
              <klarna-placement
                data-key="credit-promotion-auto-size"
                data-locale="en-US"
                data-purchase-amount={Math.trunc(price * 100)}
              />
            )}
          </PriceWrapper>
        </DesktopProductPriceWrapper>
        {/* STOCK MESSAGE -- DESKTOP */}
        <StockWrapperDesktop>
          {stockMessage && (
            <>
              <StockMessage stockMessage={stockMessage} />
              {/* LTL Message */}
              {displayLTLmessage && (
                <LTLmessageStyled>
                  <LTLmessageContent />
                </LTLmessageStyled>
              )}
              {assemblyRequiredNote && <AssemblyRequiredStyled>{assemblyRequiredNote}</AssemblyRequiredStyled>}
            </>
          )}
        </StockWrapperDesktop>
        {/* STOCK MESSAGE -- Mobile */}
        <Hidden implementation="css" mdUp>
          <StockWrapperMobile>
            {stockMessage && (
              <>
                {product?.free_shipping && (
                  <FreeShippingStyled>
                    {mattressOnly ? 'Free Shipping on Mattress Only' : 'Free Shipping'}
                  </FreeShippingStyled>
                )}
                <StockMessage stockMessage={stockMessage} />
                {/* LTL Message */}
                {displayLTLmessage && (
                  <LTLmessageStyled>
                    <LTLmessageContent />
                  </LTLmessageStyled>
                )}
                {assemblyRequiredNote && <AssemblyRequiredStyled>{assemblyRequiredNote}</AssemblyRequiredStyled>}
              </>
            )}
          </StockWrapperMobile>
        </Hidden>
        {/* SET USER DELIVERY ZONE */}
        <ShopAndDeliverTo variant="pdp" bottom={!showKlarna} initialShippingAddress={initialShippingAddress} />
        {/* Klarna Banner -- Mobile */}
        {showKlarna && (
          <Hidden implementation="css" mdUp>
            <klarna-placement
              data-key="credit-promotion-auto-size"
              data-locale="en-US"
              data-purchase-amount={Math.trunc(price * 100)}
            />
          </Hidden>
        )}
        {/* SEE IN STORE -- Mobile */}
        {renderSeeInStoreButton && availability && (
          <Hidden implementation="css" mdUp>
            {renderSeeInStoreButton && availability && renderSeeInStores()}
          </Hidden>
        )}

        {/* ADD TO CART / SEE IN STORE -- Tablet */}
        <NoSsr>
          {/* New Mattress PDP ATC */}
          <Hidden implementation="css" lgUp>
            <Hidden only="xs">
              <Box justifyContent="space-evenly" alignItems="stretch" direction="column" container>
                <Box width="100%">
                  <ProductAddToCartSection
                    quantity={quantity}
                    availability={availability}
                    product={product}
                    price={price}
                    componentPage="pdp"
                    stockMessage={stockMessage}
                    addons={addons}
                    requiredSelected={requiredAddonsSelected}
                    activeAddons={userSelectedAddons}
                    warrantyEnabled={warrantyEnabled}
                    showLocation={false}
                    hideShipping
                    moreInfoButton={false}
                    foundationSelection={foundationSelection}
                    routeToSimilarItems={routeToSimilarItems}
                    index={0}
                    source="pdp"
                    seeInStoreButton={renderSeeInStores}
                  />
                </Box>
              </Box>
            </Hidden>
          </Hidden>
        </NoSsr>
        {/* New Mattress PDP ATC */}
        {/* ADD TO CART / SEE IN STORE -- Desktop */}
        <NoSsr>
          <StyledAddToCartWrapper>
            <ProductAddToCartSection
              quantity={quantity}
              availability={availability}
              product={product}
              price={price}
              componentPage="pdp"
              stockMessage={stockMessage}
              addons={addons}
              requiredSelected={requiredAddonsSelected}
              activeAddons={userSelectedAddons}
              warrantyEnabled={warrantyEnabled}
              showLocation={false}
              hideShipping
              moreInfoButton={false}
              region={region}
              foundationSelection={foundationSelection}
              routeToSimilarItems={routeToSimilarItems}
              index={0}
              source="pdp"
              seeInStoreButton={renderSeeInStores}
            />
          </StyledAddToCartWrapper>
        </NoSsr>
        <StyledFavoriteButton
          variant="text"
          color="primary"
          fullWidth
          disableElevation
          disableRipple
          onClick={() => handleFavoriteClick(product)}
        >
          <FAVORITE_BUTTON
            checked={isFavorited}
            sku={product?.sku}
            handleClick={handleFavoriteClick}
            iconColor={isFavorited ? null : theme.palette.primary.main}
          />
          <p>{isFavorited ? 'IN YOUR FAVORITES' : 'ADD TO FAVORITES'}</p>
        </StyledFavoriteButton>
        {!noAddons && (
          <Grid item>
            <Addons
              userSelectedAddons={userSelectedAddons}
              setUserSelectedAddons={setUserSelectedAddons}
              setWarrantyEnabled={setWarrantyEnabled}
              optionalAddons={optionalAddons}
              warrantyEnabled={warrantyEnabled}
              warrantyPrice={product.warrantyPrice}
              warranty_pricing={product.warranty_pricing}
              isMattress={isMattress}
            />
          </Grid>
        )}
      </Grid>
      {/* STICKY DRAWER FOR MOBILE BRANDING/TITLE/PRICE/ADD TO CART */}
      <Hidden implementation="css" smUp>
        <Box
          position="fixed"
          bottom={0}
          left={0}
          padding="10px"
          bgcolor="white"
          width="100vw"
          zIndex={2}
          borderTop="1px solid #dedede"
        >
          <PriceWrapper isMobile>
            <ProductPrice
              price={currencyFormatUS(!isMattressWithFoundations ? price : foundationOptionPrice)}
              strikeThroughPrice={currencyFormatUS(strikeThroughPrice)}
              showStrikeThrough={isEmpty(foundationSelection)}
              sale={sale || strikeThrough}
              strikeThrough={strikeThrough}
              availability={availability}
              showFinancing={showFinance}
              financeAmount={financeAmountText}
              monthly={financeNumMonths}
              prequalifiedLink={`${PREQUALIFIED_LINK}rgbcml5d2&modalapply=true`}
              prequalifyButton={() => (
                <ApplyButton
                  asButton
                  url={`${PREQUALIFIED_LINK}rgbcml5d1&modalapply=true`}
                  category="pdp"
                  action="see-prequalify"
                  title="See if you Prequalify"
                  data-testid={generateTestId('prequalify', 'link')}
                >
                  See if you Prequalify
                </ApplyButton>
              )}
            />
          </PriceWrapper>
          <StyledAddToCartSection direction="row" spacing={1}>
            <ProductAddToCartSection
              quantity={quantity}
              availability={availability}
              product={product}
              price={price}
              componentPage="pdp"
              stockMessage={stockMessage}
              addons={addons}
              requiredSelected={requiredAddonsSelected}
              activeAddons={userSelectedAddons}
              warrantyEnabled={warrantyEnabled}
              showLocation={false}
              hideShipping
              moreInfoButton={false}
              region={region}
              foundationSelection={foundationSelection}
              routeToSimilarItems={routeToSimilarItems}
              index={0}
              source="pdp"
              seeInStoreButton={renderSeeInStores}
            />
            <FAVORITE_BUTTON checked={isFavorited} handleClick={handleFavoriteClick} sku={product?.sku} />
          </StyledAddToCartSection>
        </Box>
      </Hidden>
    </SystemProvider>
  )
}

ProductInfo.propTypes = {
  product: PropTypes.object,
  items_in_room: PropTypes.object,
  strikeThrough: PropTypes.bool,
  strikeThroughPrice: PropTypes.number,
  free_shipping: PropTypes.bool,
  region: PropTypes.string,
  userSelectedAddons: PropTypes.arrayOf(AddonModelPropType),
  setUserSelectedAddons: PropTypes.func,
  requiredAddonsSelected: PropTypes.arrayOf(AddonModelPropType),
  room_savings: PropTypes.number,
  foundationsMattressBanner: PropTypes.objectOf(PropTypes.any),
  foundationSelection: PropTypes.objectOf(PropTypes.any),
  setFoundationSelection: PropTypes.func,
}

export default ProductInfo
