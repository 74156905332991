/* eslint-disable import/no-duplicates */
// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles, Fab, Typography, DialogContentText, DialogContent, Dialog, Grid } from '@material-ui/core'
import Banner from '@templates/strapi-cms/content-types/Banner'
// constants
import { breakPoints } from '@constants/styles'

const DialogWrapper = styled(Dialog)`
  z-index: 6000 !important;
  overflow: visible !important;
  -webkit-overflow-scrolling: unset !important;
`
const DialogContainer = styled(DialogContent)`
  padding: 8px 32px;
  border: 0.4em solid #dedede;
  @media only screen and (max-width: ${breakPoints.medium}) {
    padding: 0;
  }
`
const CloseButton = styled(Fab)`
  position: absolute;
  z-index: 7000;
  top: -1.5em;
  right: -2em;
  background-color: #0053a0;
  width: 4em;
  height: 4em;
  & svg {
    font-size: 3em;
  }
`
const DialogContainerText = styled(DialogContentText)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
`

const TopSection = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${breakPoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`

const HeadingContainer = styled('div')`
  text-align: center;
  align-items: center;
  margin-top: 1.2rem;
  @media only screen and(max-width: ${breakPoints.medium}) {
    max-width: 100%;
    width: 100%;
  }
`
const HeadingText = styled(Typography)`
  font-size: 1.688rem !important;
  color: #203858;
  font-weight: 600;
  text-align: center;
  @media only screen and (max-width: ${breakPoints.small}) {
    font-size: 1.4rem !important;
  }
`

const BannerContainer = styled('div')(({ theme }) => ({
  '*': {
    color: theme.palette.primary.dark,
  },
  '& .strapi-markdown': {
    padding: 0,
  },
  '& > div:last-of-type': {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      borderTop: '1px solid #dedede',
      flexDirection: 'column',
    },
  },
  h4: {
    fontSize: '0.9rem',
  },
  p: {
    fontSize: '0.8rem',
  },
  ul: {
    padding: 0,
  },
  li: {
    fontSize: '0.8rem',
  },
  img: {
    backgroundColor: 'transparent!important',
  },
}))

const BannerHeading = styled(Grid)({
  paddingLeft: '20px',
  '& > div:last-of-type': {
    paddingLeft: '40px',
  },
  [`@media only screen and (max-width: ${breakPoints.small})`]: {
    display: 'none',
  },
})

const styles = theme => ({
  paper: {
    overflow: 'visible',
  },
  paperWidthMd: {
    maxWidth: '800px',
    maxHeight: '600px',
    [theme.breakpoints.down('md')]: {
      margin: '0 15px',
    },
  },
})

/**
 * @name MattressFoundationModal
 * @description modal component for mattress foundation
 */
const MattressFoundationModal = ({ data, shouldShowModal, closeModal, classes }) => {
  if (!data) return null
  const { Banner: content } = data

  return (
    <DialogWrapper
      label={data?.Title}
      open={shouldShowModal}
      onClose={closeModal}
      maxWidth="md"
      classes={{ paper: classes.paper, paperWidthMd: classes.paperWidthMd }}
    >
      <CloseButton onClick={closeModal} size="medium" color="primary" aria-label="add">
        <CloseIcon />
      </CloseButton>
      <DialogContainer>
        <DialogContainerText>
          <BannerContainer container>
            <TopSection container>
              <HeadingContainer container direction="column" item xs={10} md={8}>
                <HeadingText variant="h1">{content?.Title}</HeadingText>
              </HeadingContainer>
            </TopSection>
            <BannerHeading container xs={12} sm={12} md={12}>
              <Grid item xs={2} sm={2} md={2} />
              <Grid item xs={4} sm={4} md={4}>
                Types of foundations:
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Features and Benefits:
              </Grid>
            </BannerHeading>
            <Banner data={data} />
          </BannerContainer>
        </DialogContainerText>
      </DialogContainer>
    </DialogWrapper>
  )
}

MattressFoundationModal.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  closeModal: PropTypes.any,
  shouldShowModal: PropTypes.any,
  classes: PropTypes.object,
}

export default withStyles(styles)(MattressFoundationModal)
