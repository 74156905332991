import React from 'react'
import { any, shape, string, func, number, instanceOf, oneOfType } from 'prop-types'
import SaleFlagComponent from '@components/SaleFlag'
import { getProductSaleFlagType, SaleFlagType } from '@helpers/product'

const SaleFlag = ({ availabilityDate, bonusBuyOverrideText, onClick, product, sale, testId }) => {
  let flagText = getProductSaleFlagType({ product, availabilityDate, sale })

  if (!flagText) return null

  let cursor = 'default'
  if (flagText === SaleFlagType.BonusBuy) {
    flagText = bonusBuyOverrideText
    cursor = 'pointer'
  }

  return (
    <span {...(onClick ? { onClick, onKeyDown: onClick, role: 'button', tabIndex: '0' } : {})} style={{ cursor }}>
      <SaleFlagComponent label={flagText} testId={testId} />
    </span>
  )
}

SaleFlag.propTypes = {
  availabilityDate: oneOfType([string, instanceOf(Date)]),
  bonusBuyOverrideText: string,
  onClick: func,
  product: shape({
    closeout: any,
    inStock: string,
    qualifier_value: number,
  }),
  sale: any,
  testId: string,
}

SaleFlag.defaultProps = {
  availabilityDate: undefined,
  bonusBuyOverrideText: 'BONUS BUY!',
  product: {},
  sale: '',
}

export default SaleFlag
