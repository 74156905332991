import React from 'react'
import classNames from 'classnames'
import { objectOf, bool, string, any, number, func, array } from 'prop-types'
import { productUrl } from '@helpers/route'
import { PLP } from '@constants/page-names'
import { addToDataLayer, analyticsProduct } from '@helpers/google-tag-manager'
import RTGLink from '@shared/link'
import ProductLocation from '../../../product/product-parts/product-location'
import AddToCartModal from '../../../product/product-parts/add-to-cart-modal'
import '../../css/add-to-cart.sass'

export default class AddToCart extends React.Component {
  state = {
    modalOpen: false,
    stockMessage: '',
  }

  componentDidUpdate(prevProps) {
    const { stockMessage } = this.props
    if (stockMessage && prevProps.stockMessage !== stockMessage) {
      return () =>
        this.setState({
          stockMessage,
        })
    }
    return null
  }

  onAddToCart = event => {
    event.preventDefault()
    const { modalOpen } = this.state
    const { product, componentPage } = this.props
    addToDataLayer('click', componentPage, 'add to cart', product.sku)
    if (!modalOpen) {
      this.setState({
        modalOpen: true,
      })
    }
  }

  closeModal = () => {
    const { modalOpen } = this.state
    const { removeFocus } = this.props
    if (modalOpen) {
      if (removeFocus) {
        removeFocus(false)
      }
      this.setState({
        modalOpen: false,
      })
    }
  }

  render() {
    const { modalOpen, stockMessage: stockMessageState } = this.state
    const {
      product,
      availability,
      price,
      focused,
      stockMessage,
      index,
      source,
      addons,
      requiredSelected,
      activeAddons,
      buttonText,
      promoDescription,
      promoItems,
      promoQualifierQuantity,
      promoTargetQuantity,
      promoTargetPrice,
      promoStrikethroughPrice,
      warrantyEnabled,
      hideShipping,
      moreInfoButton,
      showLocation,
    } = this.props
    let route = null
    if (product && product.route) route = product.route

    return (
      <>
        <div
          className={classNames('buy-now-popup grid-x', {
            focused,
            offerButton: buttonText === 'add offer to cart',
          })}
        >
          {!hideShipping && (
            <div className="instock-instore cell small-12 large-4">
              <ProductLocation product={product} />
            </div>
          )}
          <div className="cell small-12 large-8 grid-x space-between slider-width">
            {showLocation && (
              <div className="instock-instore cell small-4">
                <ProductLocation product={product} />
              </div>
            )}
            {moreInfoButton && (
              <div className={classNames(`cell small-5`)}>
                {product && product.title && product.sku && (
                  <RTGLink
                    data={{
                      slug: productUrl(route),
                      altDesc: product.title ? product.title : '',
                      text: 'More Info',
                    }}
                    trackingData={{
                      event: 'ee_click',
                      ecommerce: {
                        click: { position: index + 1, products: [analyticsProduct(product, 1, index + 1)] },
                      },
                    }}
                    className="buy-now more-info"
                  />
                )}
              </div>
            )}
            <div className={classNames(`cell plp-add-to-cart-button small-${moreInfoButton ? '5' : '12'}`)}>
              {(availability &&
                (!stockMessage ||
                  stockMessage === '' ||
                  (!stockMessage.includes('Out of Stock ') &&
                    !stockMessage.includes('Not available in your region ') &&
                    !stockMessage.includes('Discontinued ') &&
                    !stockMessage.includes('Available Soon ')))) ||
              source === 'search' ? (
                <button
                  className={classNames('buy-now', {
                    focused,
                  })}
                  aria-label={`Add To Cart for ${product.title ? product.title : ''}`}
                  onClick={this.onAddToCart}
                  type="button"
                >
                  {buttonText || 'add to cart'}
                </button>
              ) : (
                <button
                  className={classNames('buy-now unavailable', {
                    focused,
                  })}
                  aria-label={`Unavailable ${product.title ? product.title : ''}`}
                  type="button"
                  disabled
                >
                  not available
                </button>
              )}
            </div>
          </div>
        </div>
        {modalOpen && (
          <AddToCartModal
            pageName={PLP}
            modalOpen={modalOpen}
            product={product}
            price={price}
            closeModal={this.closeModal}
            stockMessage={stockMessageState || stockMessage}
            addons={addons}
            requiredSelected={requiredSelected}
            activeAddons={activeAddons}
            index={index}
            promoDescription={promoDescription}
            promoItems={promoItems}
            promoQualifierQuantity={promoQualifierQuantity}
            promoTargetQuantity={promoTargetQuantity}
            promoTargetPrice={promoTargetPrice}
            promoStrikethroughPrice={promoStrikethroughPrice}
            warrantyEnabled={warrantyEnabled}
          />
        )}
      </>
    )
  }
}

AddToCart.propTypes = {
  product: objectOf(any),
  availability: bool,
  price: number,
  focused: bool,
  stockMessage: string,
  index: number,
  source: string,
  addons: array,
  requiredSelected: bool,
  activeAddons: array,
  buttonText: string,
  promoDescription: string,
  promoItems: array,
  promoQualifierQuantity: number,
  promoTargetQuantity: number,
  promoTargetPrice: number,
  promoStrikethroughPrice: number,
  warrantyEnabled: bool,
  moreInfoButton: bool,
  removeFocus: func,
  componentPage: string,
  hideShipping: bool,
  showLocation: bool,
}
