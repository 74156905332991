import React from 'react'
import { bool, arrayOf, any, string, func } from 'prop-types'
import { productAvailability } from '@helpers/product'
import ProductVariation from './product-variation'
import SimpleSlider from '../../../shared/slider'
import '../../../../assets/css/components/product/product-parts/product-variations.sass'

const ProductVariations = props => {
  const { variations, heading, type, isMobile, handleVariationClick, productSku, noImage } = props
  const availableVariations = variations
    ? variations.filter(v => productAvailability(v) || !v.catalog_availability)
    : []

  let maxSlides = 2
  const minSlides = 2
  if (type === 'team') {
    maxSlides = isMobile ? 3 : 10
  }
  return (
    (availableVariations && availableVariations.length > 1 && (
      <div className="product-variation-list cell small-12 large-6 ">
        {heading && <div className="variation-heading cell small-1">{heading}</div>}
        <div className="variation-slider cell small-12">
          <SimpleSlider
            data={{
              heading: '',
              describedby: `cell${productSku}`,
              label: 'Product Variation',
            }}
            maxSlides={maxSlides}
            minSlidesMobile={minSlides}
            infinite
            noExtraMargin
          >
            {availableVariations.map((variation, index) => (
              <ProductVariation
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                productSku={productSku}
                variation={variation}
                handleClick={handleVariationClick}
                noImage={noImage}
                sliderTitle="Product Variation"
              />
            ))}
          </SimpleSlider>
        </div>
      </div>
    )) ||
    null
  )
}

ProductVariations.propTypes = {
  variations: arrayOf(any),
  heading: string,
  type: string,
  isMobile: bool,
  handleVariationClick: func,
  productSku: string,
  noImage: bool,
}

export default ProductVariations
