import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'
import { getRegionZone } from '@helpers/geo-location'
import { getWarrantyDataByRegionZone } from '@helpers/product'
import { getStateEligiblity } from '@helpers/extendedServiceContract'
import WarrantyModalTemplate from '@shared/modals/warranty-modal'
import { Addon } from '../views/Addons'
import PriceAtom from '../../../@rtg2022/components/atoms/Price/Price'

const ProductWarrantyLink = props => {
  const { setWarrantyEnabled, warrantyEnabled, warrantyPrice, warranty_pricing = null } = props
  const [showWarrantyModal, setWarrantyModal] = useState(false)
  const regionZoneData = getRegionZone()
  const warrantyData = warranty_pricing ? getWarrantyDataByRegionZone(warranty_pricing, regionZoneData) : warrantyPrice
  const WarrantyModal = warrantyData ? WarrantyModalTemplate : null
  const isStateEligible = getStateEligiblity()

  if (!isStateEligible || !warrantyData?.price) return null

  return (
    <>
      <Addon>
        <input
          data-testid="addonCheckbox"
          id="serviceContractPDP"
          name="serviceContract"
          type="checkbox"
          checked={warrantyEnabled}
          onChange={e => setWarrantyEnabled(e.target.checked)}
        />
        <label data-testid="serviceContact" id="serviceContractPDPDesc" htmlFor="serviceContractPDP">
          <span style={{ lineHeight: '16px' }}>
            3 Year Furniture Protection Plan - <PriceAtom price={currencyFormatUS(warrantyData.price)} />
            <button type="button" onClick={() => setWarrantyModal(true)} aria-describedby="serviceContractPDPDesc">
              <span style={{ fontSize: 14, color: '#0053a0', display: 'block' }}>What's Covered?</span>
            </button>
          </span>
        </label>
      </Addon>
      {WarrantyModal && (
        <WarrantyModal
          shouldShowModal={showWarrantyModal}
          closeModal={() => setWarrantyModal(false)}
          region={warrantyData.provider_name}
        />
      )}
    </>
  )
}

ProductWarrantyLink.propTypes = {
  setWarrantyEnabled: PropTypes.func,
  warrantyEnabled: PropTypes.bool,
  warrantyPrice: PropTypes.number,
  warranty_pricing: PropTypes.object,
}

export default ProductWarrantyLink
