import React from 'react'
import { array, func, string } from 'prop-types'
import { styled } from '@mui/material'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: '100%',
  height: 50,
  marginTop: 20,
  marginBottom: 15,
  [theme.breakpoints.down('sm')]: {
    marginTop: '0',
  },
}))

const StyledInputLabel = styled(InputLabel)(() => ({
  transform: 'scale(0.75)',
}))

const StyledSelectDropdown = styled(Select)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}))

const SelectVariation = ({ variations, heading, productSku, handleChange, className }) => (
  <StyledFormControl className={className}>
    <StyledInputLabel htmlFor="outlined-size-native-simple">{heading}</StyledInputLabel>
    <StyledSelectDropdown
      native
      label={heading}
      onChange={event => handleChange(event.target.value)}
      value={variations.find(v => v.sku === productSku).variation_value}
      inputProps={{
        heading,
        id: 'outlined-size-native-simple',
      }}
    >
      {variations.map(v => (
        <option key={v.sku} value={v.variation_value}>
          {v.variation_value}
        </option>
      ))}
    </StyledSelectDropdown>
  </StyledFormControl>
)

SelectVariation.propTypes = {
  variations: array,
  heading: string,
  handleChange: func,
  productSku: string,
  className: string,
}

export default SelectVariation
