// dependencies
import React from 'react'
import { array, any, object, func, bool, number } from 'prop-types'
import { Map as MapComponent, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
import { useTheme } from '@mui/material'
// helpers
import { letters } from '@helpers/string-helper'

// https://developers.google.com/maps/documentation/javascript/reference/marker
export const markerImage = {
  path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
  fillOpacity: 1,
  fillColor: '#0050b5',
  strokeWeight: 2,
  strokeColor: 'white',
  scale: 2,
}

const Map = ({
  google,
  children,
  currentCoordinates,
  markers,
  onLocationClick,
  onMarkerMounted,
  onMapClicked,
  activeMarker,
  showingInfoWindow,
  className,
  style,
  selectedMarker,
  ignoreDistance,
  hideCurrentMarker,
  zoom = 11,
}) => {
  const theme = useTheme()

  return (
    <MapComponent
      className={className}
      style={style}
      initialCenter={{ lat: currentCoordinates.currentLat, lng: currentCoordinates.currentLng }}
      center={{ lat: currentCoordinates.currentLat, lng: currentCoordinates.currentLng }}
      google={google}
      zoom={zoom}
      onClick={onMapClicked}
      mapTypeControl={false}
      scaleControl={false}
      streetViewControl={false}
      rotateControl={false}
      fullscreenControl={false}
    >
      {!hideCurrentMarker && (
        <Marker
          position={{ lat: currentCoordinates.currentLat, lng: currentCoordinates.currentLng }}
          title="Current Location"
          name="Current Location"
          icon={{
            url: 'https://maps.google.com/mapfiles/marker.png',
          }}
        />
      )}
      {(!ignoreDistance ? markers[0].distance < 100 : true) &&
        markers.map((mark, index) => (
          <Marker
            ref={onMarkerMounted}
            position={{ lat: mark.lat, lng: mark.lng }}
            title={mark.name}
            icon={{
              ...markerImage,
              ...(mark.storeNumber === selectedMarker.storeNumber && { fillColor: theme.palette.error.main }),
              anchor: new google.maps.Point(12, 17),
              labelOrigin: new google.maps.Point(12, 9),
            }}
            label={{
              text: letters[index],
              fontFamily: theme.typography.fontFamily,
              color: '#ffffff',
              fontSize: '18px',
            }}
            key={`${mark.name}|${mark.title}`}
            onClick={() => onLocationClick(index, mark)}
          />
        ))}
      <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
        {children}
      </InfoWindow>
    </MapComponent>
  )
}

Map.propTypes = {
  children: any,
  className: any,
  style: any,
  google: object,
  currentCoordinates: object,
  markers: array,
  onLocationClick: func,
  onMarkerMounted: func,
  onMapClicked: func,
  activeMarker: any,
  selectedMarker: object,
  showingInfoWindow: bool,
  ignoreDistance: bool,
  hideCurrentMarker: bool,
  zoom: number,
}

export default GoogleApiWrapper({
  apiKey: `${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`,
})(Map)
