import React from 'react'

import PropTypes from 'prop-types'
import { Box, styled, Typography } from '@mui/material'
import { useTest } from '@hooks/useTest'

// ! TEMPORARY SALE FLAG
// TODO: replace with RTG 2022 saleflag component

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.common.white,
}))

const Parent = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  backgroundColor: theme.palette.error.dark,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 5,
  padding: '8px 16px',
}))

const SaleFlag = ({ label, testId }) => {
  const { generateTestId } = useTest()
  const dataTestId = generateTestId('sale', 'flag')

  return (
    <Parent data-testid={testId || dataTestId}>
      <Label fontSize={20}>{label}</Label>
    </Parent>
  )
}

SaleFlag.propTypes = {
  label: PropTypes.string,
  testId: PropTypes.string,
}

export default SaleFlag
