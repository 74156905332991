import React, { useState, useMemo } from 'react'
import { double, string } from 'prop-types'
import { addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import { styled, Dialog, Box, Button } from '@mui/material'
import { Close } from '@mui/icons-material'

const LearnMoreButton = styled('button')`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`

const LearnMorePromoFeeContent = styled(Box)`
  display: grid;
  overflow: auto;
  gap: 1rem;
  position: relative;
  padding-top: 40px;

  .dialog-close-btn {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }

  .grid {
    display: grid;
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .gap-2px {
    gap: 2px;
  }
  .gap-4 {
    gap: 1rem;
  }
  .flex-1 {
    flex: 1 1 0%;
  }
  .items-center {
    align-items: center;
  }
  .rounded {
    border-radius: 0.25rem /* 4px */;
  }
  .p-2 {
    padding: 0.5rem /* 8px */;
  }
  .min-w-150px {
    min-width: 150px;
  }
  .inline-block {
    display: inline-block;
  }
  .align-middle {
    vertical-align: middle;
  }
  .text-50-p {
    font-size: 50%;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .w-full {
    width: 100%;
  }
  .max-w-250px {
    max-width: 250px;
  }

  .bg-roomy-primary-blue-5 {
    background-color: #0053a00d;
  }
  .bg-roomy-primary-blue-10 {
    background-color: #0053a01a;
  }

  .text-roomy-primary-dark-blue {
    --tw-text-opacity: 1;
    color: rgb(7 38 59 / var(--tw-text-opacity)) /* #07263b */;
  }
  .text-primary-dark {
    --tw-text-opacity: 1;
    color: rgb(7 38 59 / var(--tw-text-opacity)) /* #07263b */;
  }

  .heading-3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    color: #333333;

    @media screen and (min-width: 1024px) {
      font-size: 25px;
    }
  }
  .paragraph-1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #333333;
  }
  .paragraph-2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #333333;
  }

  .md-flex-row {
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  .md-min-h-\[50px\] {
    @media (min-width: 768px) {
      min-height: 50px;
    }
  }
  .md-rounded-r-none {
    @media (min-width: 768px) {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .md-justify-center {
    @media (min-width: 768px) {
      justify-content: center;
    }
  }
  .md-rounded-l-none {
    @media (min-width: 768px) {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  sup {
    position: relative;
    top: -0.45em;
  }

  b {
    font-weight: 600;
  }
`

function LearnMorePromoFee({ price, promoMessageHTML }) {
  const [isLearnMoreFeeOpen, setIsLearnMoreFeeOpen] = useState(false)

  const closeLearnMoreFeeModal = () => {
    setIsLearnMoreFeeOpen(false)
  }

  const openLearnMoreFeeModal = () => {
    setIsLearnMoreFeeOpen(true)

    addToDataLayer_nextgen('financing_learn_more')
  }

  const priceWithPromoFee = useMemo(() => (price ? price * 1.02 : 0), [price])
  const [productDollars, productCents] = priceWithPromoFee?.toFixed(2)?.split('.') || [0, 0]

  if (!price) return null
  return (
    <>
      <LearnMoreButton
        type="button"
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          openLearnMoreFeeModal()
        }}
      >
        Learn More
      </LearnMoreButton>
      <Dialog
        open={isLearnMoreFeeOpen}
        onClose={closeLearnMoreFeeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LearnMorePromoFeeContent p={3}>
          <button className="dialog-close-btn" type="button" onClick={closeLearnMoreFeeModal}>
            <Close />
          </button>
          <div className="flex flex-col gap-2px md-flex-row">
            <div className="bg-roomy-primary-blue-10 flex flex-1 items-center rounded p-2 md:min-h-50px md-rounded-r-none">
              <p className="paragraph-2 text-roomy-primary-dark-blue">Total Payment Including 2% Promo Fee</p>
            </div>
            <div className="bg-roomy-primary-blue-5 flex min-w-150px items-center rounded p-2 md:min-h-50px md-justify-center md-rounded-l-none">
              <p className="paragraph-2 text-roomy-primary-dark-blue">
                <span className="text-primary-dark inline-block">
                  <sup className="align-middle text-50-p">$</sup>
                  {productDollars}
                  {productCents !== '00' && productCents !== '0' && productCents && (
                    <sup className="align-middle text-50-p">{productCents}</sup>
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="paragraph-1 grid gap-4" dangerouslySetInnerHTML={{ __html: promoMessageHTML }} />
          <Button
            variant="contained"
            onClick={closeLearnMoreFeeModal}
            sx={{
              margin: 'auto',
              maxWidth: '250px',
              width: '100%',
            }}
          >
            Close
          </Button>
        </LearnMorePromoFeeContent>
      </Dialog>
    </>
  )
}

LearnMorePromoFee.propTypes = {
  price: double,
  promoMessageHTML: string,
}

export default LearnMorePromoFee
