import React, { useState } from 'react'
import PropTypes, { any, array, bool, func, number, objectOf, string } from 'prop-types'
import styled from 'styled-components'
import { productUrl } from '@helpers/route'
import { renderSeeInStore } from '@helpers/product'
import { addToDataLayer, analyticsProduct } from '@helpers/google-tag-manager'
import RTGLink from '@shared/link'
import { setOrderInfo } from '@helpers/checkout/global'
import { useTest } from '@hooks/useTest'
// import { Button } from '@rtgdev/design-system'
import Button from '@mui/material/Button' /* TODO Button needs refactor conclusion on design-system side - scheduled for PDP 2B */
import Grid from '@material-ui/core/Grid'
import { fonts, theme, breakPoints } from '@constants/styles'
import { AddonModelPropType } from '@models/api/products/addon-model'
import ProductLocation from './product-location'
import AddToCartModal from './add-to-cart-modal'
import ApplePayButton from '../../checkout/checkout-parts/payment-section/apple-pay'
import SeeInStores from './product-see-in-stores-wrapper'

const Span = styled.span`
  color: ${theme.primary};
  display: inline-block;
  font-size: ${fonts.txtMedium};
  font-weight: ${theme.weight.regular};
`

const AddToCartGrid = styled(Grid)`
  @media only screen and (max-width: ${breakPoints['medium-max']}) {
    margin-top: 15px;
  }
`

const AddToCartButton = styled(Button)`
  font-size: 1rem;
  line-height: 1.875;
`

const SeeInStoresGrid = styled(Grid)`
  @media only screen and (max-width: ${breakPoints['medium-max']}) {
    display: none;
  }
`

const ProductAddToCartSection = props => {
  const [modalOpen, setModalOpen] = useState(false)
  const {
    activeAddons,
    addons,
    availability,
    buttonText,
    componentPage,
    foundationSelection,
    hideShipping,
    index,
    moreInfoButton,
    price,
    product,
    promoDescription,
    promoItems,
    promoQualifierQuantity,
    promoTargetPrice,
    promoTargetQuantity,
    promoStrikethroughPrice,
    quantity,
    region,
    removeFocus,
    requiredSelected,
    routeToSimilarItems,
    seeInStoreButton,
    showLocation,
    source,
    stockMessage,
    warrantyEnabled,
  } = props
  const { generateTestId } = useTest()
  const route = product?.route || null
  const renderSeeInStoreButton = renderSeeInStore({
    region,
    delivery_type: product.delivery_type,
  })
  // Mattress PDP add multiple skus here
  const onAddToCart = event => {
    event.preventDefault()
    addToDataLayer('click', componentPage, 'add to cart', product.sku)
    if (!modalOpen) {
      setModalOpen(true)
    }
  }

  const closeModal = () => {
    if (modalOpen) {
      if (removeFocus) {
        removeFocus(false)
      }
      setModalOpen(false)
    }
  }

  const isDisabled = sku => {
    const unavailableSkus = ['28070693', '28065692']
    return unavailableSkus.includes(sku)
  }

  const renderSeeInStoresButton = () => {
    if (seeInStoreButton) return seeInStoreButton()
    return (
      <SeeInStores componentPage="pdp" primary_image={product.primary_image} sku={product.sku} title={product.title} />
    )
  }

  if (!stockMessage) return null
  return (
    <>
      {(!hideShipping || showLocation) && <ProductLocation product={product} />}
      <div>
        {moreInfoButton && product?.title && product?.sku && (
          <RTGLink
            data={{
              slug: productUrl(route),
              text: 'More Info',
            }}
            trackingData={{
              event: 'ee_click',
              ecommerce: {
                click: {
                  position: index,
                  list: `${product && product.sliderTitle}`,
                  products: [analyticsProduct(product, 1, index)],
                },
              },
            }}
            className="buy-now more-info"
          >
            <Span>More Info</Span>
          </RTGLink>
        )}
        <Grid container direction="column" justifyContent="center" alignItems="stretch">
          {(availability &&
            (!stockMessage ||
              stockMessage === '' ||
              !['Out of Stock', 'Discontinued ', 'Available Soon ', 'Not available in your region '].includes(
                stockMessage,
              ))) ||
          source === 'search' ? (
            <Grid item container spacing={1} justifyContent="center">
              <AddToCartGrid item xs={12}>
                {/* PDP Mattress need to append 2 skus here as opposed to 1 */}
                {/* But in the end just opening modal */}
                <AddToCartButton
                  aria-label={`Add To Cart for ${product.title ? product.title : ''}`}
                  color="primary"
                  data-testid={generateTestId('addtocart', 'button')}
                  disabled={isDisabled(product.sku)}
                  fullWidth
                  onClick={onAddToCart}
                  variant="contained"
                >
                  {buttonText || 'add to cart'}
                </AddToCartButton>
              </AddToCartGrid>
              {renderSeeInStoreButton && (
                <SeeInStoresGrid item xs={12}>
                  {renderSeeInStoresButton()}
                </SeeInStoresGrid>
              )}
              {componentPage === 'pdp' &&
                !isDisabled(product.sku) &&
                typeof window !== 'undefined' &&
                window.safari !== undefined && (
                  <Grid item xs data-testid={generateTestId('addtocart button', 'applepay')}>
                    <ApplePayButton
                      activeAddons={activeAddons}
                      componentPage={componentPage}
                      isExpress
                      isPDP
                      price={price}
                      product={product}
                      setOrderInfo={() => setOrderInfo('Apple Pay', 'selectedPaymentType')}
                      type="buy"
                      warrantyEnabled={warrantyEnabled}
                    />
                  </Grid>
                )}
            </Grid>
          ) : (
            <AddToCartButton
              aria-label={`Shop Similar for ${product.title ? product.title : ''}`}
              color="primary"
              data-testid={generateTestId('shopsimilar', 'button')}
              fullWidth
              onClick={routeToSimilarItems}
              variant="contained"
            >
              SHOP SIMILAR PRODUCTS
            </AddToCartButton>
          )}
        </Grid>
      </div>
      {modalOpen && (
        <AddToCartModal
          activeAddons={activeAddons}
          addons={addons}
          closeModal={closeModal}
          foundationSelection={foundationSelection}
          index={index}
          modalOpen={modalOpen}
          price={price}
          product={product}
          promoDescription={promoDescription}
          promoItems={promoItems}
          promoQualifierQuantity={promoQualifierQuantity}
          promoTargetPrice={promoTargetPrice}
          promoTargetQuantity={promoTargetQuantity}
          promoStrikethroughPrice={promoStrikethroughPrice}
          quantity={quantity}
          requiredSelected={requiredSelected}
          source={source}
          stockMessage={stockMessage}
          warrantyEnabled={warrantyEnabled}
        />
      )}
    </>
  )
}

ProductAddToCartSection.propTypes = {
  activeAddons: PropTypes.arrayOf(AddonModelPropType),
  addons: array,
  availability: bool,
  buttonText: string,
  componentPage: string,
  foundationSelection: objectOf(any),
  hideShipping: bool,
  index: number,
  moreInfoButton: bool,
  price: number,
  product: objectOf(any),
  promoDescription: string,
  promoItems: array,
  promoQualifierQuantity: PropTypes.oneOfType([string, number]),
  promoTargetPrice: number,
  promoTargetQuantity: number,
  promoStrikethroughPrice: number,
  quantity: number,
  region: string,
  removeFocus: func,
  requiredSelected: PropTypes.arrayOf(AddonModelPropType),
  routeToSimilarItems: func,
  seeInStoreButton: func,
  showLocation: bool,
  source: string,
  stockMessage: string,
  warrantyEnabled: bool,
}

export default ProductAddToCartSection
