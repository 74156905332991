import React from 'react'
import { connect } from 'react-redux'
import { bool, func, string, objectOf, any } from 'prop-types'
import { getStockMessage } from '@helpers/product'
import { setStockMessage } from '@redux/modules/product'
import { setShippingAddress } from '@redux/modules/location'
import { ApolloConsumer } from '@apollo/client'
// import SeeInStores from './product-see-in-stores'
import ProductStockMessage from './product-stockMessage'
import ProductLocationPopout from './product-location-popout'
import '../../../assets/css/components/product/product-location.sass'

class ProductLocation extends React.Component {
  state = {
    show: false,
  }

  componentDidMount() {
    const { cart, onSetStockMessage, stockMessage, product, addToCart, doNotCallGetStockMessage = false } = this.props
    // todo refactor ProductLocation component, it's just workaround
    if (doNotCallGetStockMessage) {
      onSetStockMessage(stockMessage)
    } else if (cart) {
      getStockMessage(product, onSetStockMessage)
    } else if (addToCart && stockMessage) {
      onSetStockMessage(stockMessage)
    }
  }

  componentDidUpdate(nextProps) {
    const { onSetStockMessage, stockMessage, cart } = this.props
    if (!cart && nextProps.stockMessage !== stockMessage) {
      onSetStockMessage(stockMessage)
    }
  }

  closeModal = () => this.setState({ show: false })

  handleShow = () => {
    const { show } = this.state
    this.setState({ show: !show })
  }

  render() {
    const { shipping_address, onSetShippingAddress, product, cart, list, stockMessage, hideStockMessage } = this.props
    const { show } = this.state
    const addrArr = shipping_address ? shipping_address.split(',') : []
    if (addrArr[0] === '') {
      onSetShippingAddress(addrArr[1])
    }
    return (
      <>
        <ApolloConsumer>
          {client => (
            <ProductLocationPopout
              handlers={{
                closeModal: this.closeModal,
                handleShow: this.handleShow,
              }}
              list={list}
              show={show}
              cart={cart}
              product={product}
              shipping_address={shipping_address}
              apolloClient={client}
            />
          )}
        </ApolloConsumer>
        <ProductStockMessage
          hideStockMessage={hideStockMessage}
          stockMessage={stockMessage}
          customerAssemblyRequired={product && product.customer_assembly_required}
        />
      </>
    )
  }
}

ProductLocation.propTypes = {
  cart: bool,
  onSetStockMessage: func,
  stockMessage: any,
  product: objectOf(any),
  addToCart: func,
  onSetShippingAddress: func,
  list: bool,
  doNotCallGetStockMessage: bool,
  shipping_address: string,
  hideStockMessage: bool,
}

const mapStateToProps = state => ({
  ...state.location,
})

const mapDispatchToProps = dispatch => ({
  onSetShippingAddress: shipping_address => dispatch(setShippingAddress(shipping_address)),
  onSetStockMessage: message => dispatch(setStockMessage(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductLocation)
