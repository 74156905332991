import React from 'react'
import { Grid, Button, styled } from '@mui/material'
import { breakPoints } from '@constants/styles'
import { string } from 'prop-types'
import {
  RTGBaby,
  Beautyrest,
  BeautyrestBlack,
  BeautyrestSilver,
  BedsToGo,
  Bedgear,
  Casper,
  CindyCrawfordHome,
  Disney,
  DisneyPrincess,
  DisneyStarWars,
  DrewJonathan,
  DrewJonathanKids,
  EricChurch,
  IComfortSerta,
  Kids,
  Kingsdown,
  LaZBoy,
  RTGModern,
  Molecule,
  Nectar,
  Patio,
  Purple,
  Restonic,
  RTGKids,
  RTGPatio,
  StearnsAndFoster,
  Sealy,
  Serta,
  SofiaVergaraCollection,
  Sunbrella,
  Simmons,
  Teens,
  Tempurpedic,
  Therapedic,
} from '../../../assets/images/brands'

// The total size of importing all 28 of these logos is 455 KB
// PR 2546 sets up the loadable package for dynamic imports
// When that PR gets merged, update this component to dynamically import these images

const StyledImageContainer = styled(Grid)({
  margin: '0',
  '& img': {
    maxHeight: '25px',
    marginBottom: '5px',
    [`@media only screen and (max-width: ${breakPoints.small})`]: {
      maxHeight: '20px',
    },
  },
})

const StyledButton = styled(Button)({
  textTransform: 'none',
  lineHeight: 0,
  fontWeight: 'bold',
  [`@media only screen and (max-width: ${breakPoints.small})`]: {
    fontSize: '0.8rem',
    width: 'auto',
    fontWeight: 'unset',
    textTransform: 'capitalize',
    textDecoration: 'underline',
  },
})

const BrandText = styled('div')({
  textTransform: 'capitalize',
})

export const brandsConfig = {
  baby: {
    brandImg: RTGBaby,
  },
  beautyrest: {
    brandImg: Beautyrest,
  },
  'beautyrest black': {
    brandImg: BeautyrestBlack,
  },
  'beautyrest silver': {
    brandImg: BeautyrestSilver,
  },
  'beds to go': {
    brandImg: BedsToGo,
  },
  bedgear: {
    brandImg: Bedgear,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/bedgear',
  },
  casper: {
    brandImg: Casper,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/casper',
  },
  'cindy crawford': {
    brandImg: CindyCrawfordHome,
  },
  disney: {
    brandImg: Disney,
  },
  'disney princess': {
    brandImg: DisneyPrincess,
  },
  'disney star wars': {
    brandImg: DisneyStarWars,
  },
  'drew & jonathan': {
    brandImg: DrewJonathan,
    brandUrl: 'https://www.roomstogo.com/furniture/collections/drew-and-jonathan',
    maxHeight: '40px',
  },
  'drew & jonathan kids': {
    brandImg: DrewJonathanKids,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/drew-jonathan-kids',
    maxHeight: '70px',
  },
  'eric church highway to home': {
    brandImg: EricChurch,
  },
  'serta icomfort': {
    brandImg: IComfortSerta,
  },
  kids: {
    brandImg: Kids,
  },
  kingsdown: {
    brandImg: Kingsdown,
  },
  'la-z-boy': {
    brandImg: LaZBoy,
  },
  modern: {
    brandImg: RTGModern,
    brandUrl: 'https://www.roomstogo.com/furniture/collections/modern',
  },
  molecule: {
    brandImg: Molecule,
  },
  nectar: {
    brandImg: Nectar,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/nectar',
  },
  patio: {
    brandImg: Patio,
  },
  purple: {
    brandImg: Purple,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/purple',
  },
  restonic: {
    brandImg: Restonic,
  },
  'rtg kids': {
    brandImg: RTGKids,
  },
  'rtg patio': {
    brandImg: RTGPatio,
  },
  'stearns and foster': {
    brandImg: StearnsAndFoster,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/stearns-and-foster',
  },
  sealy: {
    brandImg: Sealy,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/sealy',
  },
  serta: {
    brandImg: Serta,
  },
  'serta perfect sleeper': {
    brandImg: Serta,
  },
  'sofia vergara': {
    brandImg: SofiaVergaraCollection,
  },
  'fifth & shore': {
    brandImg: Sunbrella,
  },
  sunbrella: {
    brandImg: Sunbrella,
  },
  simmons: {
    brandImg: Simmons,
  },
  teens: {
    brandImg: Teens,
  },
  'tempur-pedic': {
    brandImg: Tempurpedic,
    brandUrl: 'https://www.roomstogo.com/mattress/brands/tempur-pedic',
  },
  therapedic: {
    brandImg: Therapedic,
  },
}

const Branding = ({ brand }) => {
  if (!brand) return null
  const { brandImg, brandUrl, maxHeight } = brandsConfig[brand] || {}
  if (!brandImg) return null
  return (
    <StyledImageContainer item container alignItems="center" justifyContent="flex-start">
      <img
        alt={`${brand} logo`}
        role="presentation"
        aria-hidden="true"
        src={brandImg}
        style={(brandUrl || maxHeight) && { maxHeight: maxHeight || '20px' }}
      />
      {brandUrl && (
        <StyledButton variant="text" href={brandUrl} target="_blank">
          Learn More About&nbsp;<BrandText>{brand}</BrandText>
        </StyledButton>
      )}
    </StyledImageContainer>
  )
}

Branding.propTypes = {
  brand: string,
}

export default Branding
