import React from 'react'
import { getRegionZone } from '@helpers/geo-location'
import { hasIn } from 'lodash'
import '@comp-sass/product/product-parts/product-includes.sass'
import { arrayOf, object } from 'prop-types'

const ProductIncludes = ({ itemsInRoom }) => {
  const { region } = getRegionZone()

  return (
    <div className="product-includes  small-12 large-8">
      <div className="includes-heading">INCLUDES</div>
      <ul className="items-in-room grid-x small-12">
        {hasIn(itemsInRoom, region) &&
          itemsInRoom[region].map((item, index) => (
            <li className="small-4 cell" key={item.sku || index}>
              {item.generic_name}
            </li>
          ))}
        {Array.isArray(itemsInRoom) &&
          itemsInRoom.map((item, index) => <li key={item.sku || index}>{item.generic_name}</li>)}
      </ul>
    </div>
  )
}

ProductIncludes.propTypes = {
  itemsInRoom: arrayOf(object),
}

export default ProductIncludes
