import React from 'react'
import { styled, Box, Typography, Grid, Link } from '@mui/material'
import PropTypes from 'prop-types'
import { useTest } from '@hooks/useTest'
import { getPriceStrings } from '@helpers/product'
import LearnMorePromoFee from '../../molecules/ProductTile/LearnMorePromoFee'
import PriceAtom from './Price'

// COMPONENT STYLES
const RoomSavings = styled(Box)(() => ({
  fontSize: 12,
  'span:first-of-type': {
    fontSize: 16,
    color: '#e31c3d',
    fontWeight: 600,
  },
}))

const RoomSavingsPriceContainer = styled('span')(() => ({
  '&>span:first-of-type': {
    marginRight: 5,
  },
}))

const Parent = styled(Grid)(({ theme }) => ({
  lineHeight: '1rem',
  fontFamily: theme.typography.fontFamily,
}))

const Price = styled(Box, {
  shouldForwardProp: props => props !== 'sale',
})(({ theme, sale }) => ({
  fontSize: 30,
  fontWeight: 700,
  color: `${sale ? theme.palette.error.main : '#000'}`,
  '.strikethrough': {
    fontSize: 18,
    fontWeight: 400,
    color: '#000',
  },
}))

const FreeShipping = styled('span')(() => ({
  fontSize: 21,
  fontWeight: 700,
  color: '#e31c3d',
}))

const Financing = styled(Box)(() => ({
  fontSize: 16,
}))

const ProductPrice = ({
  availability = true,
  freeShipping,
  freeShippingMattressOnly,
  price,
  sale,
  showStrikeThrough,
  strikeThrough,
  strikeThroughPrice,
  financeAmount,
  monthly,
  showFinancing = !!financeAmount,
  prequalifyButton,
  room_savings,
  prequalifiedLink,
  cleanVariant,
  promoMessageHTML,
}) => {
  const { generateTestId } = useTest()
  const renderPrequalifyButton = () => {
    if (typeof prequalifyButton === 'function') return prequalifyButton()
    return (
      <Link
        href={prequalifiedLink}
        title="Prequalify for Rooms to Go credit card"
        target="_blank"
        gtm-category="pdp"
        gtm-action="link click"
        gtm-label="Link - synchrony prequal - Get Prequalified - pdp"
        role="button"
        aria-disabled="false"
        data-testid="link:prequalify-pdp"
        underline="always"
      >
        <Typography
          data-testid={generateTestId('prequalify', 'link')}
          style={{ whiteSpace: 'nowrap', fontSize: '12px', lineHeight: '24px' }}
        >
          See if you Prequalify
        </Typography>
      </Link>
    )
  }

  const renderFinancing = () => {
    if (monthly && !cleanVariant) {
      return (
        <Box style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <Typography
            data-testid={generateTestId('product-price', 'finance-calculator')}
            style={{ color: '#e31c3d', whiteSpace: 'nowrap' }}
          >
            <strong>
              <PriceAtom price={financeAmount} />
              /mo.
            </strong>{' '}
            with <strong>{`${monthly} months financing*`}</strong>
          </Typography>
          <Typography style={{ whiteSpace: 'nowrap' }}>&nbsp;</Typography>
          {renderPrequalifyButton()}
        </Box>
      )
    }
    if (monthly && cleanVariant) {
      return (
        <>
          <div>
            <PriceAtom price={financeAmount} />
            /mo. for
          </div>
          <div>{`${monthly} months*`}</div>
        </>
      )
    }
    return `${financeAmount} per month*`
  }

  if (!availability) return null

  return (
    <Parent container spacing={1} alignItems="center" data-testid={generateTestId('component', 'productPrice')}>
      {strikeThrough && showStrikeThrough && (
        <Grid item>
          <Price sale={sale} data-testid={generateTestId('product-price', 'strikethrough')}>
            <PriceAtom className="strikethrough" price={strikeThroughPrice} strikeThrough />
          </Price>
        </Grid>
      )}
      <Grid item>
        <Price
          sale={sale}
          className={sale ? 'strikethrough-sale' : null}
          data-testid={generateTestId('product', 'price')}
        >
          <PriceAtom price={price} />
        </Price>
      </Grid>
      {freeShipping && (
        <Grid item>
          <FreeShipping>{freeShippingMattressOnly ? 'Free Shipping on Mattress Only' : 'Free Shipping'}</FreeShipping>
        </Grid>
      )}
      {!!room_savings && (
        <Grid item xl={8}>
          <RoomSavings style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <RoomSavingsPriceContainer style={{ whiteSpace: 'nowrap' }}>
              <PriceAtom price={room_savings} />
              savings
            </RoomSavingsPriceContainer>

            <span style={{ whiteSpace: 'nowrap' }}>(Compared to items sold separately)</span>
          </RoomSavings>
        </Grid>
      )}
      {showFinancing && (
        <Grid item xs>
          <Financing data-testid={generateTestId('product-price', 'finance-calculator')}>
            <>
              {renderFinancing()}{' '}
              <LearnMorePromoFee price={getPriceStrings(price)} promoMessageHTML={promoMessageHTML} />
            </>
          </Financing>
        </Grid>
      )}
    </Parent>
  )
}

ProductPrice.propTypes = {
  availability: PropTypes.bool,
  freeShipping: PropTypes.bool,
  freeShippingMattressOnly: PropTypes.bool,
  price: PropTypes.string,
  sale: PropTypes.bool,
  showStrikeThrough: PropTypes.bool,
  strikeThrough: PropTypes.bool,
  strikeThroughPrice: PropTypes.string,
  financeAmount: PropTypes.string,
  prequalifyButton: PropTypes.any,
  monthly: PropTypes.number,
  showFinancing: PropTypes.bool,
  room_savings: PropTypes.number,
  prequalifiedLink: PropTypes.string,
  cleanVariant: PropTypes.bool,
  promoMessageHTML: PropTypes.string,
}

export default ProductPrice
