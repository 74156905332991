import React from 'react'
import classNames from 'classnames'
import { objectOf, any, bool, string, arrayOf, number, func } from 'prop-types'
import { productUrl } from '@helpers/route'
import { scrollToPLP } from '@helpers/product'
import { analyticsProduct } from '@helpers/google-tag-manager'
import { fetchProductBySku } from '@services/product'
import AlternateImages from '../../../product/product-parts/product-alternate-images'
import AddToCart from '../product-parts/product-add-to-cart'
import ProductFinancing from '../../../product/product-parts/product-financing'
import ProductLocation from '../../../product/product-parts/product-location'
import ProductPrice from '../../../product/product-parts/product-price'
import Variations from '../product-parts/product-variations'
import RTGLink from '../../../shared/link'
import SaleFlag from '../../../shared/sale-flag'
import ProductIncludes from '../product-parts/product-includes'
import '../../css/product-info-grid-2-view.sass'
import { getRegionZone } from '../../../../lib/helpers/geo-location'

export default class ProductInfoGridIncludes extends React.Component {
  state = {
    focused: false,
  }

  setFocus = () => {
    this.setState({
      focused: true,
    })
  }

  removeFocus = (event = null) => {
    const code = event ? event.keyCode || event.which : null
    if (
      !code ||
      (code === 9 && event.target.className === 'buy-now focused') ||
      !event ||
      (event.shiftKey &&
        event.keyCode === 9 &&
        typeof event.target.className === 'string' &&
        event.target.className.includes('product-image-link'))
    ) {
      this.setState({
        focused: true,
      })
    }
  }

  handleVariationClick = variation => {
    const { setProduct } = this.props
    fetchProductBySku(variation.sku).then(product => setProduct(product))
  }

  renderProductVariations() {
    const { orderedProductVariations, product } = this.props
    let variationType = null
    if (orderedProductVariations) {
      if (orderedProductVariations.color && orderedProductVariations.color.length > 0) {
        variationType = 'color'
      } else if (orderedProductVariations.finish && orderedProductVariations.finish.length > 0) {
        variationType = 'finish'
      }
    }
    return variationType ? (
      <div className={`${variationType}-variation`}>
        <Variations
          handleVariationClick={this.handleVariationClick}
          variations={orderedProductVariations[variationType]}
          productSku={product.sku}
          noImage={variationType === 'size'}
        />
      </div>
    ) : null
  }

  isSliderVisible() {
    const { orderedProductVariations } = this.props
    let isVisible = false
    if (orderedProductVariations) {
      if (orderedProductVariations.color && orderedProductVariations.color.length > 0) {
        isVisible = true
      } else if (orderedProductVariations.finish && orderedProductVariations.finish.length > 0) {
        isVisible = true
      }
    }
    return isVisible
  }

  render() {
    const {
      product,
      price,
      strikethrough,
      strikethroughPrice,
      availability,
      sale,
      fullWidth,
      currentImage,
      setImage,
      index,
      source,
      isMobile,
      addons,
      displayQuantity,
      showFinance,
      financeAmount,
      last,
    } = this.props
    const { focused } = this.state
    let route = null
    const { region, zone } = getRegionZone()
    if (product && product.route) route = product.route

    return (
      <div
        onKeyDown={e => this.removeFocus(e)}
        className={classNames('product-tile plp-grid plp-grid-includes card', {
          'pop-out': !fullWidth,
          wide: fullWidth,
          focused,
        })}
        role="presentation"
      >
        {product && (
          <>
            <SaleFlag product={product} />
            <div className="item product grid-x grid-margin-x">
              <span id={`cell${product.sku}`} className="hide508">
                {`${product.brand ? product.brand : ''} ${product.category}`}
              </span>

              {displayQuantity > 1 && <div className="item-count">{`qty: ${displayQuantity}`}</div>}
              <RTGLink
                className="product-image-link"
                data={{
                  slug: productUrl(route),
                  category: 'product tile',
                  action: 'click',
                  label: product.sku,
                  value: index + 1,
                }}
                trackingData={{
                  event: 'ee_click',
                  ecommerce: { click: { position: index + 1, products: [analyticsProduct(product, 1, index + 1)] } },
                }}
                aria-hidden="true"
                tabIndex="-1"
              >
                <span
                  className={classNames('product-image', { loading: !(product.primary_image || product.grid_image) })}
                  data-src={`${`${product.primary_image_item}|${product.grid_image_room}`}`}
                >
                  {(product.primary_image_item || product.grid_image_room || product.grid_image) && (
                    <img
                      src={`${product.primary_image}&${isMobile ? 'h=250' : 'h=385'}`}
                      className="small-image"
                      alt=""
                      role="presentation"
                      aria-hidden="true"
                      onLoad={last ? scrollToPLP() : _ => _}
                    />
                  )}
                </span>
              </RTGLink>
              {fullWidth && (
                <AlternateImages
                  image={product.primary_image}
                  alternate_images={product.alternate_images}
                  currentImage={currentImage}
                  setImage={setImage}
                />
              )}
              <div className="product-details-section grid-x cell">
                <div className="product-details-data grid-x">
                  {fullWidth && (
                    <div className="instock-instore cell small-12">
                      <ProductLocation />
                    </div>
                  )}
                  <div className="product-variation-title cell small-12 grid-x">
                    <div
                      className={`title-area medium-12 ${
                        this.isSliderVisible() ? 'small-7 large-7' : 'small-12 large-12'
                      }`}
                    >
                      <RTGLink
                        data={{
                          slug: productUrl(route),
                          category: 'product tile',
                          action: 'click',
                          label: product.sku,
                        }}
                        aria-describedby={`${product.sku}-aria-price`}
                      >
                        <span className="product-info cell small-12 ">
                          <span className="product-title link" dangerouslySetInnerHTML={{ __html: product.title }} />
                        </span>
                      </RTGLink>
                    </div>
                    <div className="product-variation-image-list cell small-5 medium-12 large-5">
                      <div className="product-variations">
                        {product.variations?.size ? (
                          <div
                            className={classNames('product-variation-info', {
                              hidden: product.category === 'mattress bedding',
                            })}
                          >
                            More Sizes Available
                          </div>
                        ) : (
                          <div
                            className={classNames('product-variation-info', {
                              hidden:
                                product.upgrade_items && product.category === 'livingroom'
                                  ? !product.upgrade_items.some(item => item.title.toLowerCase().includes('sleeper')) ||
                                    (product.sub_category &&
                                      product.sub_category.some(category => category.includes('Sleeper Sofas'))) ||
                                    product.title.toLowerCase().includes('sleeper')
                                  : true,
                            })}
                          >
                            Sleeper Option Available
                          </div>
                        )}
                        {product.variations && (
                          <div className="product-variation-swatches">{this.renderProductVariations()}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="includes-price grid-x cell">
                    {product && product.items_in_room && <ProductIncludes itemsInRoom={product.items_in_room} />}
                    <div
                      className={classNames('product-pricing small-12 large-4', {
                        'large-4': fullWidth,
                      })}
                    >
                      {price && (
                        <div className="product-price cell grid-x small-12">
                          <div className="locationRedo grid-x small-6 medium-7">
                            <ProductLocation product={product} />
                          </div>
                          <div className="priceRedo grid-x small-6 medium-5 large-12">
                            <RTGLink
                              data={{
                                slug: productUrl(route),
                                category: 'product tile',
                                action: 'click',
                                label: product.sku,
                              }}
                              id={`${product.sku}-aria-price`}
                              tabIndex="-1"
                            >
                              <div className={classNames({ 'small-6': showFinance, 'small-12': !showFinance })}>
                                <ProductPrice
                                  price={price}
                                  sale={sale}
                                  strikethrough={strikethrough}
                                  strikethroughPrice={strikethroughPrice}
                                />
                                {showFinance && (
                                  <div className="small-6">
                                    <ProductFinancing financeAmount={financeAmount} />
                                  </div>
                                )}
                              </div>
                            </RTGLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="buy-button cell small-12 grid-x">
                    {/* handle TRUE free shipping text in the "you recently viewed" & "you may also like" sections/sliders */}
                    {product && product.free_shipping && source !== 'search' && (
                      <p className="free-shipping slider-free-shipping">Free Shipping</p>
                    )}
                    {/* handle FALSE/empty free shipping text in the "you recently viewed" & "you may also like" sections/sliders */}
                    {product && !product.free_shipping && source !== 'search' && (
                      <p className="free-shipping slider-no-free-shipping">&nbsp;&nbsp;</p>
                    )}
                    {region !== 'OOM' && (
                      <AddToCart
                        availability={availability}
                        product={product}
                        price={price}
                        componentPage="product tile"
                        focused={focused}
                        removeFocus={this.removeFocus}
                        source={source}
                        addons={addons || product.addon_items}
                        activeAddons={[]}
                        index={index + 1}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

ProductInfoGridIncludes.propTypes = {
  product: objectOf(any),
  price: number,
  strikethrough: bool,
  strikethroughPrice: number,
  availability: bool,
  sale: bool,
  fullWidth: bool,
  currentImage: string,
  setImage: func,
  index: number,
  source: string,
  isMobile: bool,
  addons: arrayOf(any),
  displayQuantity: number,
  showFinance: bool,
  financeAmount: number,
  last: bool,
  setProduct: func,
  orderedProductVariations: objectOf(any),
}
