// dependencies
import React, { useRef } from 'react'
import { objectOf, any, bool, string, func } from 'prop-types'
import { styled } from '@mui/material'
import classNames from 'classnames'
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined'

const StyledDeliveryInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const ProductLocationDeliveryInfo = ({ product, toggleLocationModal, shipping_address, list, show }) => {
  const btnRef = useRef(null)
  const isCrib = product && product.generic_name === 'crib' && product.delivery_type === 'K'
  return (
    <div className="product-location cell small-12">
      <StyledDeliveryInfo className="delivery-info">
        {product && !product.free_shipping && (
          <>
            <LocalShippingIcon className="icon shipping" />
            <p>
              Premium Delivery&nbsp;
              <br />
              {!isCrib ? <>&amp; Set-Up to</> : 'to'}
              <button
                data-testid="plpLocationButton"
                type="button"
                ref={btnRef}
                aria-expanded="false"
                className={classNames('location-button', {
                  active: show,
                })}
                onClick={toggleLocationModal}
              >
                <span className="hide508">Premium Delivery {!isCrib ? 'and setup ' : ''}to: </span>
                {shipping_address}
              </button>
            </p>
          </>
        )}
        {/* handle free shipping text PLP pages */}
        {product && product.free_shipping && !list && <p className="free-shipping">Free Shipping</p>}
      </StyledDeliveryInfo>
    </div>
  )
}

ProductLocationDeliveryInfo.propTypes = {
  product: objectOf(any),
  toggleLocationModal: func,
  shipping_address: string,
  list: bool,
  show: bool,
}

export default ProductLocationDeliveryInfo
