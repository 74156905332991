import React, { useEffect, useState } from 'react'

import { styled, Hidden, InputBase, ListItemText, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { currencyFormatUS } from '../../lib/helpers/string-helper'
import PriceAtom from '../components/atoms/Price/Price'

const Label = styled(InputLabel)({
  paddingLeft: '10px',
  fontSize: '11px !important',
  color: '#585860',
})

const FoundationImage = styled('img')(({ theme }) => ({
  width: 50,
  marginRight: 15,
  aspectRatio: 'auto',
  [theme.breakpoints.up('md')]: {
    width: 100,
  },
}))

const Input = styled(InputBase)(({ theme }) => ({
  border: 'solid 1px #4b4b4b',
  width: '100%',
  height: '41px',
  '& > div': {
    border: '1px solid black',
    paddingLeft: '12px',
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    height: 'inherit !important',
    '&:focus:': {
      border: 'solid 1px theme.palette.primary.main',
      backgroundColor: 'unset',
    },
    '&:first-of-type': {
      display: 'block !important',
      maxHeight: '1.45rem',
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& > div': {
      // fontSize: '80%',
      maxWidth: 'calc(100vw - 8rem)',
    },
  },
  [theme.breakpoints.between('md', 'lg')]: {
    '& > div': {
      maxWidth: '10rem',
    },
  },
}))

const StyledWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  container: 'flex',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    padding: '1em',
  },
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: '0.8rem',
  paddingRight: '10px',
  whiteSpace: 'break-spaces',
  [theme.breakpoints.up('md')]: {
    maxWidth: '280px',
  },
}))

const getItemPrice = (pricing, region, zone, isOnSale) => {
  if (!pricing) return 0
  if (!region || (!zone && zone !== 0)) return pricing?.default_price || 0
  if (!isOnSale) return pricing[`${region}_${zone}_list_price`]
  return pricing[`${region}_${zone}_sale_price`]
}

const setSortByPrice = (region, zone, isOnSale) => foundations => {
  const data = [...foundations]
  return data.sort((a, b) => {
    const priceA = getItemPrice(a.pricing, region, zone, isOnSale(a))
    const priceB = getItemPrice(b.pricing, region, zone, isOnSale(b))
    return priceA - priceB
  })
}

const getRenderValue = foundations => sku => {
  const selected = foundations.find(x => x.sku === sku) || { title: 'No Foundation' }
  return selected.title
}

const FoundationChooser = ({
  foundations,
  region,
  zone,
  isOnSale,
  onSelect = () => {},
  foundationSelection = {},
  className,
}) => {
  const [value, setValue] = useState('')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const handleChange = event => {
    const selected = event.target.value
    const item = foundations.find(x => x.sku === selected && x.title !== 'No Foundation') || {}
    onSelect(item)
    setValue(selected)
  }

  useEffect(() => {
    const initial = foundations.find(x => x.defaultSelection === true) || { sku: '59999977' }
    setValue(initial.sku)
  }, [foundations])

  const sortByPrice = setSortByPrice(region, zone, isOnSale)

  const renderFoundationDropdown = () => (
    <Select
      input={<Input value={value} onChange={handleChange} />}
      MenuProps={{
        PaperProps: {
          style: { border: 'solid 1px grey', marginTop: 5 },
        },
      }}
      labelId="selections-label"
      id="selections-select"
      onChange={handleChange}
      value={value}
      renderValue={getRenderValue(foundations)}
      variant="standard"
      className={className}
    >
      {sortByPrice(foundations).map(item => {
        const priceDiff =
          getItemPrice(item.pricing, region, zone, isOnSale(item)) -
          getItemPrice(foundationSelection?.pricing, region, zone, isOnSale(foundationSelection))
        return (
          <StyledMenuItem key={item.sku} value={item.sku}>
            {item?.primary_image && <FoundationImage src={item.primary_image} alt={item.label} />}
            <StyledListItemText>{item.title}</StyledListItemText>
            {Boolean(priceDiff) && (
              <Typography variant="caption" sx={{ fontSize: '0.8rem', color: isOnSale(item) && 'red' }}>
                {priceDiff > 0 ? '+' : '-'} <PriceAtom price={currencyFormatUS(Math.abs(priceDiff))} />
              </Typography>
            )}
          </StyledMenuItem>
        )
      })}
    </Select>
  )

  return (
    <StyledWrapper>
      <Label id="selections-label">Add A Foundation</Label>
      <FormControl sx={{ width: '100%' }}>
        <Hidden implementation="css" smDown>
          {renderFoundationDropdown()}
        </Hidden>
        <Hidden implementation="css" mdUp>
          <Tooltip title={foundationSelection?.title}>{renderFoundationDropdown()}</Tooltip>
        </Hidden>
      </FormControl>
    </StyledWrapper>
  )
}

FoundationChooser.propTypes = {
  region: PropTypes.oneOf(['SE', 'FL', 'TX', 'OOM']),
  zone: PropTypes.number,
  isOnSale: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  foundations: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      selectionLabel: PropTypes.string.isRequired,
      selectionRequired: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      defaultSelection: PropTypes.bool.isRequired,
      parentHeaderLabel: PropTypes.string.isRequired,
      childHeaderLabel: PropTypes.string.isRequired,
      catalog_availability: PropTypes.object,
      primary_image: PropTypes.string,
      dimension_image: PropTypes.string,
      pricing: PropTypes.object,
      route: PropTypes.string.isRequired,
    }),
  ).isRequired,
  foundationSelection: PropTypes.object,
  className: PropTypes.string,
}

export default FoundationChooser
