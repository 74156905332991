import React from 'react'
import classNames from 'classnames'
import '../../../assets/css/components/product/product-parts/pricing.sass'
import { currencyFormatUS } from '@helpers/string-helper'
import { number, bool } from 'prop-types'

const ProductPrice = ({ price, sale, strikethroughPrice, strikethrough, availability = true }) => (
  <>
    {availability && (
      <>
        {strikethrough ? (
          <>
            <span className={classNames('price-tag', { sale, strikethrough })}>
              {`${currencyFormatUS(strikethroughPrice)}`}
            </span>
            <span className={classNames('price-tag', { sale, 'strikethrough-sale': strikethrough })}>
              {`${currencyFormatUS(price)}`}
            </span>
          </>
        ) : (
          <div className={classNames('price-tag', { sale })}>{`${currencyFormatUS(price)}`}</div>
        )}
      </>
    )}
  </>
)

ProductPrice.propTypes = {
  price: number,
  sale: bool,
  strikethroughPrice: number,
  strikethrough: bool,
  availability: bool,
}

export default ProductPrice
