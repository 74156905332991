import React from 'react'
import PropTypes from 'prop-types'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const SliderArrow = ({ text, caption, describedby, label, classNames, heading, instance, onClick }) => (
  <button
    type="button"
    data-role="none"
    className={classNames}
    gtm-category="slider"
    gtm-action="slider scroll"
    gtm-label={heading}
    onClick={onClick}
    aria-describedby={describedby || null}
    aria-label={text && label ? `${text} for ${label}` : null}
  >
    {text.includes('Next') ? (
      <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
    ) : (
      <ArrowBackIosNewIcon sx={{ fontSize: '1rem' }} />
    )}

    <span className="hide508">{`${text} for ${caption || heading || ''}`}</span>
  </button>
)

SliderArrow.propTypes = {
  text: PropTypes.string,
  caption: PropTypes.string,
  heading: PropTypes.string,
  classNames: PropTypes.string,
  describedby: PropTypes.string,
  onClick: PropTypes.func,
  instance: PropTypes.object,
  label: PropTypes.any,
}

export default SliderArrow
