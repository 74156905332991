import PropTypes from 'prop-types'
import React from 'react'
import { styled } from '@mui/material'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { productAvailability } from '@helpers/product'
import { makeStyles } from '@material-ui/core/styles'
import { analyticsProduct } from '@helpers/google-tag-manager'

const StyledFormControl = styled(FormControl)(() => ({
  minWidth: '100%',
  height: 50,
  marginTop: 15,
  marginBottom: 15,
}))

const StyledSelectDropdown = styled(Select)(({ theme }) => ({
  width: '100%',
  maxWidth: 355,
  [theme.breakpoints.down('md')]: {
    maxWidth: 'unset',
  },
}))

function OptionSelect({ options, handleChange, productSku, filterAvailability }) {
  const filteredUpgrades = options.filter(u => u.sku && u.title && u.pricing)
  let availableUpgrades =
    filteredUpgrades && filterAvailability
      ? filteredUpgrades.filter(u => productAvailability(u) || u.sku === productSku)
      : filteredUpgrades
  const currentUpgrade = availableUpgrades?.filter(u => u.sku === productSku)[0]
  availableUpgrades = availableUpgrades?.filter(u => u.sku !== productSku)
  if (currentUpgrade) {
    availableUpgrades.unshift(currentUpgrade)
  }
  const upgradesWithAnalytics = availableUpgrades.map((upgrade, index) => ({
    ...upgrade,
    analyticsProduct: analyticsProduct(upgrade, 1, index + 1),
  }))

  return (
    <StyledFormControl>
      <InputLabel shrink id="optionsLabel" htmlFor="outlined-size-native-simple-options">
        Options
      </InputLabel>
      <StyledSelectDropdown
        data-testid="productOptions"
        native
        label="Options"
        labelId="optionsLabel"
        onChange={event => {
          const productInfo =
            upgradesWithAnalytics[upgradesWithAnalytics.findIndex(u => u.label === event.target.value)].analyticsProduct
          handleChange(event.target.value, {
            list: `upgrade_${event.target.value.toLowerCase()}`,
            position: upgradesWithAnalytics.findIndex(u => u.label === event.target.value),
            products: productInfo,
          })
        }}
        inputProps={{
          heading: 'Options',
          id: 'outlined-size-native-simple-options',
        }}
      >
        {upgradesWithAnalytics.map(o => (
          <option key={o.sku} value={o.label}>
            {o.label
              .toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')}
          </option>
        ))}
      </StyledSelectDropdown>
    </StyledFormControl>
  )
}

OptionSelect.propTypes = {
  filterAvailability: PropTypes.any,
  handleChange: PropTypes.func,
  options: PropTypes.shape({
    filter: PropTypes.func,
  }),
  productSku: PropTypes.any,
}

export default OptionSelect
