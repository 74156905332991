import React from 'react'
import { any, bool } from 'prop-types'
import { getFromBrowserStorage } from '@helpers/storage'
import { stripHtml } from '@helpers/general'
import StockMessage from '../../../@rtg2022/components/atoms/StockMessage/StockMessage'

const getContentByVariant = (variant, stockMessage, stockMessageOutput, customerAssemblyRequired) => {
  let content = ''
  const stockMessageHTML = stockMessage && stripHtml(stockMessage)

  if (stockMessage) {
    const baseContent = (
      <>
        {stockMessageOutput}
        <StockMessage stockMessage={stockMessage} />
      </>
    )

    if (variant === 'VariantA' || variant === 'VariantB') {
      if (variant === 'VariantB' && stockMessage === 'Available Now ') {
        content = baseContent
      } else {
        content = <>{customerAssemblyRequired ? 'CUSTOMER ASSEMBLY REQUIRED.' : ''}</>
      }
    } else {
      content = baseContent
    }

    if (stockMessage.includes('vendor')) {
      content = baseContent
    }
  }

  return content
}

const ProductStockMessage = ({ stockMessage, customerAssemblyRequired, hideStockMessage }) => {
  if (hideStockMessage) {
    return null
  }

  let stockMessageOutput
  switch (stockMessage) {
    case 'Available Now ':
      stockMessageOutput = <i className="icon checkmark" />
      break
    case 'Out of Stock ' || 'Not available in your region ':
      stockMessageOutput = <i className="icon close" />
      break
    case !stockMessage || stockMessage === '':
      stockMessageOutput = <div className="loading" />
      break
    default:
      stockMessageOutput = null
  }

  const stockMessageVariant = getFromBrowserStorage('session', 'StockMessageTest')

  return (
    <div>{getContentByVariant(stockMessageVariant, stockMessage, stockMessageOutput, customerAssemblyRequired)}</div>
  )
}

ProductStockMessage.propTypes = {
  stockMessage: any,
  customerAssemblyRequired: bool,
  hideStockMessage: bool,
}

export default ProductStockMessage
