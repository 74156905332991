import React, { Component } from 'react'
import { func, string, bool, objectOf, any, object } from 'prop-types'
import classNames from 'classnames'
import { saveLocalStorage } from '@helpers/storage'
import { geoLocation } from '@helpers/geo-location'
import { taskDone } from '@helpers/aria-announce'
import { breakPoints } from '@constants/styles'
import { styled, keyframes, createTheme, ThemeProvider } from '@mui/material/styles'
import theme from '../../../@rtg2022/material-ui-theme'
import LocationFields from '../../shared/location-fields'
import ProductLocationDeliveryInfo from './product-location-delivery-info'

const ProductLocationPopoutContainer = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  }
`

const ProductLocationModal = styled('div')`
  @media screen and (max-width: ${breakPoints.small}) {
    position: fixed;
    display: block;
    top: 25%;
    width: 100%;
    margin: auto;
  }

  background: ${theme.palette.common.white};
  position: relative;
  max-width: 22em;
  height: auto;
  min-height: 8.5em;
  margin-bottom: -7.75em;
  overflow-y: visible;
  border: solid 2px ${theme.palette.secondary.main};
  padding: 10px;
  margin-left: 0;
  top: -1em;
  left: auto;
  text-align: center;
  z-index: 2;
  min-width: 19rem;

  &.error {
    height: 11em;
    p {
      font-size: ${theme.typography.caption.fontSize};
    }
  }
  .close-modal {
    @media screen and (max-width: ${breakPoints.small}) {
      right: -1em;
    }
    position: absolute;
    top: -1.45em;
    right: -1.45em;
    background: ${theme.palette.primary.main};
    width: 3em;
    height: 3em;
    border-radius: 50%;
    z-index: 4;
    &:hover {
      background: ${theme.palette.primary.dark};
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 2.5em;
      height: 2.5em;
      margin: auto;
      background-size: 100% !important;
    }
  }
  .form {
    position: absolute;
    z-index: 1;
    width: 94%;

    button {
      background: ${theme.palette.primary.main};
    }
  }
`
class ProductLocationPopout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: false,
      addressInput: '',
    }
  }

  handleKeydown = e => {
    if (e.key === 'Escape') {
      this.btn.focus()
    }
  }

  handleClickOutside = event => {
    const {
      handlers: { closeModal },
    } = this.props
    if (
      this.node &&
      !this.node.contains(event.target) &&
      typeof event.target.className === 'string' &&
      !event.target.className.includes('location-button')
    ) {
      closeModal()
    }
  }

  async handleSubmit(event) {
    const { addressInput } = this.state
    event.preventDefault()
    const isPRZip =
      (Number(addressInput) >= 600 && Number(addressInput) <= 799) ||
      (Number(addressInput) >= 900 && Number(addressInput) <= 999)

    /* explicitly define the allowed URLs */
    const allowedUSDevHosts = ['rtg-dev.com', 'www.rtg-dev.com']
    const allowedUSProdHosts = ['roomstogo.com', 'www.roomstogo.com']

    /* grab the domain from the URL */
    let windowHost = ''
    if (typeof window !== 'undefined') {
      windowHost = window.location.hostname
    }

    /* if the user is on .com dev or prod and enters a Puerto Rico zipcode, redirect to the PR website */
    if (allowedUSDevHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.rtg-dev.pr?zip=${Number(addressInput)}`
    } else if (allowedUSProdHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.roomstogo.pr?zip=${Number(addressInput)}`
    } else {
      const { apolloClient } = this.props
      const err = await geoLocation(apolloClient, addressInput)
      if (err) {
        this.inputField.focus()
        return this.setState({
          error: true,
        })
      }
    }
    return saveLocalStorage('changeLocReturn', 'changeLocationPDPText')
  }

  toggleLocationModal = () => {
    const {
      handlers: { handleShow },
    } = this.props
    handleShow()
    try {
      taskDone(
        () => {
          this.node.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center',
          })
        },
        100,
        'focusLocation',
      )
    } catch (error) {
      this.setState({ error: true })
    }
  }

  handleAddressInputChange = event => {
    this.setState({
      addressInput: event.target.value,
    })
  }

  render() {
    const {
      list,
      handlers: { closeModal },
      show,
      cart,
      product,
      shipping_address,
      handleKeydown,
    } = this.props
    const { addressInput, error } = this.state

    const setInputRef = ref => {
      this.inputField = ref
    }

    return (
      <>
        {!cart && (
          <ProductLocationDeliveryInfo
            product={product}
            toggleLocationModal={this.toggleLocationModal}
            shipping_address={shipping_address}
            list={list}
            show={show}
          />
        )}
        {show && (
          <>
            <ThemeProvider theme={theme}>
              <ProductLocationPopoutContainer onClick={this.handleClickOutside} role="presentation" />
              <ProductLocationModal
                ref={node => {
                  this.node = node
                }}
                className={classNames('product-location-popout', {
                  error,
                })}
                onKeyDownCapture={handleKeydown}
              >
                <div className="form">
                  <form onSubmit={e => this.handleSubmit(e)}>
                    <LocationFields
                      inputRef={setInputRef}
                      error={error}
                      addressInput={addressInput}
                      onChange={this.handleAddressInputChange}
                      noAutoComplete={!list}
                      id="PDP"
                    />
                  </form>
                </div>
                <button type="button" className="close-modal" value="Close" onClick={closeModal}>
                  <img
                    className="icon close"
                    alt=""
                    aira-hidden="true"
                    role="presentation"
                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
                  />
                  <span className="hide508">Close</span>
                </button>
              </ProductLocationModal>
            </ThemeProvider>
          </>
        )}
      </>
    )
  }
}

ProductLocationPopout.propTypes = {
  closeModal: func,
  list: bool,
  handlers: objectOf(func),
  show: bool,
  cart: bool,
  product: objectOf(any),
  shipping_address: string,
  handleKeydown: func,
  apolloClient: object,
}

export default ProductLocationPopout
