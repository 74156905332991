import React from 'react'
import { CircularProgress } from '@material-ui/core'

const PageLoader = () => (
  <div
    style={{
      minHeight: 550,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      background: 'white',
      bottom: 0,
      zIndex: 9,
    }}
  >
    <CircularProgress />
  </div>
)

export default PageLoader
